






































































































export default function (Component) {
  Component.options.__source = "src/components/List/column.vue"
}
