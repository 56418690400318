const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'cuntomer-info-set',
    name: 'CuntomerInfoSet',
    meta: {
      title: '客户资料建档',
      auth: true,
      cache: true
    },
    component: _import('data-center/customer-information/customer-info-set/cuntomer-info-list.vue')
  },
  {
    path: 'stock-customer-set',
    name: 'StockCustomerSet',
    meta: {
      title: '存量客户建档',
      auth: true
    },
    component: _import('data-center/customer-information/stock-customer-set/stock-customer-set.vue')
  },
  {
    path: 'customer-info-manage',
    name: 'CustomerInformationManagement',
    meta: {
      title: '客户资料管理',
      auth: true,
      cache: true
    },
    component: _import(
      'data-center/customer-information/customer-info-manage/customer-info-manage.vue'
    )
  },
  {
    path: 'cuntomer-info-set-detail',
    name: 'CustomerInfoSetDetail',
    meta: {
      title: '客户资料建档',
      auth: true
    },
    component: _import('data-center/customer-information/customer-info-set/details.vue')
  },
  {
    path: 'customer-paper-report',
    name: 'CustomerPaperReport',
    meta: {
      title: '纸质药检客户维护',
      auth: true
    },
    component: _import('data-center/customer-information/customer-paper-report/index.vue')
  },
  {
    path: '/customer/initial-qualification',
    name: 'CustomerInitialQualification',
    meta: {
      title: '客户首营管理',
      auth: true,
      cache: true,
      keepAlive: true
    },
    component: _import('data-center/customer-information/customer-initial-qualification/index.vue')
  },
  {
    path: '/customer/profile-configuration',
    name: 'CustomerProfileConfiguration',
    meta: {
      title: '客户资料配置',
      auth: true,
      cache: true,
      keepAlive: true
    },
    component: _import('data-center/customer-information/customer-profile-configuration/index.vue')
  },
  {
    path: '/customer/change-approval',
    name: 'CustomerChangeApproval',
    meta: {
      title: '客户变更审批',
      auth: true,
      cache: true,
      keepAlive: true
    },
    component: _import('data-center/customer-information/customer-change-approval/index.vue')
  },
  {
    path: '/customer/invoice-type-maintenance',
    name: 'CustomerInvoiceTypeMaintenance',
    meta: {
      title: '客户发票类型维护',
      auth: true,
      cache: true,
      keepAlive: true
    },
    component: _import(
      'data-center/customer-information/customer-invoice-type-maintenance/index.vue'
    )
  },
  {
    path: '/customer/initial-qualification-detail',
    name: 'CustomerInitialQualificationDetail',
    meta: {
      title: '客户资料详情',
      auth: true
    },
    component: _import(
      'data-center/customer-information/customer-initial-qualification-detail/index.vue'
    )
  }
]

export default profile
