export default {
  namespaced: true,
  state: {
    // 设置采购方式变更列表
    providerListForWayChange: []
  },
  mutations: {
    // 设置采购方式变更列表
    setProviderListForWayChange(state, val) {
      state.providerListForWayChange = val
    }
  }
}
