import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userPermissionModules'
    ])
  },
  methods: {
    permissionCheck(name) {
      let result = false
      if (this.userPermissionModules) {
        result = this.userPermissionModules.includes(name)
      }
      return result
    }
  }
}
