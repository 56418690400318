const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'introducer-dashboard',
    name: 'IntroducerDashboard',
    meta: {
      title: '引入人看板',
      auth: true
    },
    component: _import('data-center/introducer-dashboard/introducer-dashboard.vue')
  }
]

export default profile
