const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

export default [
  {
    path: '/group-purchase-management',
    name: 'GroupPurchaseList',
    meta: {
      title: '厂牌品种资质',
      auth: true,
      cache: true
    },
    component: _import('data-center/group-purchase/group-purchase-list')
  },
  {
    path: '/group-purchase-setting',
    name: 'GroupPurchaseSetting',
    meta: {
      title: '集采资质准备',
      auth: true
    },
    component: _import('data-center/group-purchase/group-purchase-setting')
  }
]
