export function isString(value) {
  return typeof value === 'string'
}
export function rewritePathHash(url = '', rewrite = '') {
  const validUrl = url && isString(url)
  const validRe = rewrite && isString(rewrite)
  let res = url
  if (validUrl && validRe) {
    const reg = /\/#\/.*/g
    res = res.replace(reg, `/#${rewrite}`)
  }
  return res
}
export function goAuthPlatform({
  pageUrl = window.location.href,
  rewrite = '',
  delay = 1000,
  blank = true
} = {}) {
  const validUrl = pageUrl && isString(pageUrl)
  const redirectUrl = blank
    ? ''
    : rewritePathHash(validUrl ? pageUrl : window.location.href, rewrite)
  const appUrl = `${process.env.VUE_APP_AUTH_URL}${
    redirectUrl ? `?redirect=${encodeURIComponent(window.btoa(redirectUrl))}` : ''
  }`
  setTimeout(() => {
    window.location.replace(appUrl)
  }, delay)
}
