import { setMonitorInfo } from '@/utils'
import { wirteLog, IS_PRODUCTION } from '@/utils/weblog'
export default {
  namespaced: true,
  state: {
    // 用户信息
    info: {
      buttonModule: []
    }
  },
  getters: {
    accessControl: (state, getters) => (key) => {
      const btnAvailable = getters.btnAccessible(key)
      const menuAvailable = getters.moduleAccessible(key)
      return btnAvailable || menuAvailable
    },
    btnAccessible: (state) => (key) => {
      let accessible = false
      if (key) {
        const { buttonModule } = state.info
        if (buttonModule) {
          accessible = buttonModule.some((v) => v === key)
        }
      }
      return accessible
    },
    moduleAccessible: (state) => (key) => {
      let accessible = false
      if (key) {
        const { moduleList } = state.info
        if (moduleList) {
          accessible = moduleList.some((v) => v === key)
        }
      }
      return accessible
    }
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    set({ state, dispatch }, info) {
      return new Promise(async (resolve) => {
        // store 赋值
        state.info = info
        setMonitorInfo(info.phone, info.name)
        if (IS_PRODUCTION) {
          wirteLog({
            username: info.name,
            remark: '登录'
          })
        }
        // 持久化
        await dispatch(
          'd2admin/db/set',
          {
            dbName: 'sys',
            path: 'user.info',
            value: info,
            user: true
          },
          { root: true }
        )
        // end
        resolve()
      })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    load({ state, dispatch }) {
      return new Promise(async (resolve) => {
        // store 赋值
        state.info = await dispatch(
          'd2admin/db/get',
          {
            dbName: 'sys',
            path: 'user.info',
            defaultValue: {},
            user: true
          },
          { root: true }
        )
        // end
        resolve()
      })
    }
  }
}
