<template>
  <!-- 表格已选择x项提示组件 -->
  <div
    v-if="isShow"
    class="table-selected"
    :style="styleConfig"
  >
    <el-checkbox
      v-model="isCheckAll"
      :indeterminate="isIndeterminate"
      @click.native.prevent
    >已选择{{ selectedNum }}项</el-checkbox>
    <span
      v-if="tableRef && isShowCancel"
      class="cancel-btn"
      @click="cancelAllSelect"
    >取消</span>
  </div>
</template>

<script>
export default {
  name: 'TableSelected',
  props: {
    selectedNum: {
      type: Number,
      default: 0
    },
    allNum: {
      type: Number,
      default: 0
    },
    tableRef: {
      type: Object,
      default: undefined
    },
    styleConfig: {
      type: Object,
      default: _ => {}
    },
    isShowCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isCheckAll: false,
      isIndeterminate: false
    }
  },
  computed: {
    isShow() {
      return this.allNum > 0
    }
  },
  watch: {
    selectedNum: {
      handler() {
        this.isIndeterminate = this.selectedNum > 0 && this.selectedNum < this.allNum
        this.isCheckAll = this.selectedNum === this.allNum
      }
    }
  },
  mounted() {
  },
  methods: {
    cancelAllSelect() {
      if (this.tableRef) {
        this.tableRef.clearSelection()
        this.selectedNum = 0
        this.$emit('update:selectedNum', 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-selected {
  margin-left: 2px;
  .el-checkbox::v-deep {
    cursor: default;
    .el-checkbox__input {
      cursor: default;
    }
    .el-checkbox__label {
      padding-left: 8px;
      color: #666666;
      font-size: 12px;
    }
  }
  .cancel-btn {
    margin-left: 9px;
    font-size: 12px;
    color: $color-primary-2;
    cursor: pointer;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/TableSelected/TableSelected.vue"
}
</vue-filename-injector>
