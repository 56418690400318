import {
  Message
} from 'element-ui'
const pmsUrl = process.env.VUE_APP_PMS_DOMAIN
import router from '@/router'
import {
  imgTypeToName,
  imgTypeToNameCustumer
} from './common'
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

// 导出excel
export function exportList(header, data, filename) {
  import('@/utils/Export2Excel').then(excel => {
    excel.export_json_to_excel({
      header,
      data,
      filename
    })
  })
}

// 导出excel
export function exportList_new(headerBef, dataBef, filename, mergeTitle) {
  const header = []
  const engNameList = []
  // 处理表头
  for (const key in headerBef) {
    if (headerBef.hasOwnProperty(key)) {
      header.push(key)
      engNameList.push(headerBef[key])
    }
  }
  const data = []
  // 处理数据
  for (const signalData of dataBef) {
    const signalList = []
    for (const item of engNameList) {
      signalList.push(signalData[item])
    }
    data.push(signalList)
  }
  import('./Export2Excel').then(excel => {
    excel.export_json_to_excel({
      header,
      data,
      filename,
      mergeTitle
    })
  })
}

// 设置侧边栏
export function setMenu(list, routers) {
  const menuList = []
  // 获取路由表
  for (const menu of list) {
    for (const router of routers) {
      if (!!router.meta && !!router.meta.module && router.meta.module === menu.module) {
        let routerResult = {}
        if (!menu.child || menu.module === 'ware_house_data') { // 无子菜单,如首页
          routerResult = router
        } else { // 有子菜单
          const childrenList = []
          for (const childMenu of menu.child) {
            for (const childRouter of router.children) {
              if (childRouter.meta.module === childMenu.module) {
                childrenList.push(childRouter)
              }
            }
          }
          router.children = childrenList
          routerResult = router
        }
        menuList.push(routerResult)
      }
    }
  }
  return menuList
}

/**
 * 将图片链接转换成base64下载
 */
export function downloadImg(url, imgName) {
  const download = (url, name) => {
    const eleLink = document.createElement('a')
    eleLink.download = name
    eleLink.href = url
    eleLink.click()
    eleLink.remove()
  }

  const downloadByBlob = (url, name) => {
    const image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob)
        download(url, name)
        // 用完释放URL对象
        URL.revokeObjectURL(url)
      })
    }
  }
  const name = imgName || url.substring(url.lastIndexOf('/') + 1, url.length)
  downloadByBlob(url, name)
}

/**
 * @description 下载流
 * @export
 */
export function downloadBlob(data, config = { options: { type: '' }, name: '下载文件' }) {
  const blobOptions = { ...config.options }
  if (!blobOptions.type) {
    data.type && (blobOptions.type = data.type)
  }
  const blob = new Blob([data], config.options)
  const link = document.createElement('a')
  const url = window.URL.createObjectURL(blob)
  link.href = url
  link.download = config.name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

// 设置查侧边栏
export function setAside(allList, selfList) { // 所有路由 应该显示自己的路由
  const result = []
  for (const itemAll of allList) {
    for (const itemSelf of selfList) {
      if (itemAll.module === itemSelf.module) { // 匹配上一级目录
        if (itemSelf.child.length === 0) { // 没有子目录的情况
          if (itemAll.children) itemAll.children = []
          result.push(itemAll)
        } else { // 有子级目录
          const childrenList = []
          // console.log('itemAll.children', itemAll.children)
          for (const itemAllChild of itemAll.children) {
            for (const itemSelfChild of itemSelf.child) {
              // console.log('itemSelf.child', itemSelf.child)
              if (itemAllChild.module === itemSelfChild.module) {
                childrenList.push(itemAllChild)
              }
            }
          }
          itemAll.children = childrenList
          result.push(itemAll)
        }
      }
    }
  }
  let hasIndex = false
  // 去除重复的index
  for (let i = 0; i < result.length; i++) {
    // console.log('i', i)
    if (result[i].path === '/index' && hasIndex) {
      result.splice(i, 1)
      i--
    }
    if (result[i].path === '/index') {
      hasIndex = true
    }
  }
  // console.log('result', result)
  return result
}

// 输入
// "data": [
// {
//   "attachmentCode": "",
//   "certificationName": "",
//   "certificationNumber": "",
//   "certificationType": 0,
//   "dateOfIssue": "",
//   "expireDate": "",
//   "id": 0,
//   "sourceId": 0,
//   "url": ""
// }
// ]
// 返回   //原先的数据结构
// [
//   {
//     'type': 1,
//     urls: [
//     // {
//     //   url: ''
//     // }
//     ],
//     'certName': '药品注册证（许可证）'
//   }
// ]

// 返回 文字识别的数据结构
// [
//   {
//     url: [{ id: '', type: '', name: '', ulr: '', dateOfIssue: '', expireDate: '', certificationNumber: '' },
//       { id: '', type: '', name: '', ulr: '', dateOfIssue: '', expireDate: '', certificationNumber: '' }
//     ]
//   },
//   {
//     url: [{ id: '', type: '', name: '', ulr: '', dateOfIssue: '', expireDate: '', certificationNumber: '' },
//       { id: '', type: '', name: '', ulr: '', dateOfIssue: '', expireDate: '', certificationNumber: '' }
//     ]
//   }
// ]

export function formaterimg_new(imgs) {
  // var tempArray = []
  var allImages = []
  for (var i = 0; i < imgs.length; i++) {
    if (imgs[i].url) {
      var tempSplit = (imgs[i].url).split(',')
      for (var ii = 0; ii < tempSplit.length; ii++) {
        var tempUrlObject = {}
        tempUrlObject.type = imgs[i].certificationType
        tempUrlObject.id = imgs[i].id
        tempUrlObject.certificationNumber = imgs[i].certificationNumber
        tempUrlObject.dateOfIssue = imgs[i].dateOfIssue
        tempUrlObject.expireDate = imgs[i].expireDate
        tempUrlObject.name = imgs[i].certificationTypeStr
        tempUrlObject.url = tempSplit[ii]
        allImages.push(tempUrlObject)
      }
    }
  }
  for (var k = 0; k < allImages.length; k++) {
    allImages[k].indexForShow = k
  }
  var resultArrayForShow = []
  for (var kk = 1; kk < 20; kk++) {
    const objectForArray = {}
    const urlArray = []
    for (var kkk = 0; kkk < allImages.length; kkk++) {
      if (String(kk) === String(allImages[kkk].type)) {
        const tempConcatObject = {}
        tempConcatObject.id = allImages[kkk].id
        tempConcatObject.type = allImages[kkk].type
        tempConcatObject.name = allImages[kkk].name
        tempConcatObject.certificationNumber = allImages[kkk].certificationNumber
        tempConcatObject.dateOfIssue = allImages[kkk].dateOfIssue
        tempConcatObject.expireDate = allImages[kkk].expireDate
        tempConcatObject.indexForShow = allImages[kkk].indexForShow
        tempConcatObject.url = allImages[kkk].url
        urlArray.push(tempConcatObject)
        objectForArray.url = urlArray
      }
    }
    resultArrayForShow.push(objectForArray)
  }
  var arrayArray = []
  for (var jj = 0; jj < resultArrayForShow.length; jj++) {
    if (resultArrayForShow[jj].url) {
      arrayArray.push(resultArrayForShow[jj])
    }
  }
  // console.log('arrayArray :>> ', arrayArray)
  return arrayArray
}

// 打开弹窗
// 传参：imgList(用于预览的照片列表) ,indexFlag(默认打开第几张照片   从0开始) ，typeForShow(add-新增证照  edit-编辑证照 attachment-查看附件) ，characterRecognition(基础信息)
export function openWindow_new(imgList, indexFlag, typeForShow, characterRecognition) {
  if (typeForShow === 'attachment') { // 点击附件  查看照片
    const previewImageInfo = {
      previewImageList: imgList,
      params: {},
      characterRecognition: characterRecognition
    }
    for (var i = 0; i < imgList.length; i++) {
      if (imgList[i].url.length) {
        for (var k = 0; k < imgList[i].url.length; k++) {
          if (indexFlag === imgList[i].url[k].url) {
            previewImageInfo.params.openIndex = imgList[i].url[k].indexForShow
          }
        }
      }
    }
    localStorage.setItem('previewImageInfo', JSON.stringify(previewImageInfo))
    const url = `${process.env.VUE_APP_PREVIEWURL_NEW}`
    const targetWindow = window.open(url, new Date().getTime().toString(), 'height=754, width=1277, top=20, left=20, toolbar=no, menubar=no, scrollbars=no, resizable=yes,location=no, status=no')
    return targetWindow
  }
  if (typeForShow === 'edit') {
    const previewImageInfo = {
      previewImageList: imgList,
      params: {},
      characterRecognition: characterRecognition
    }
    previewImageInfo.params.openIndex = indexFlag
    localStorage.setItem('previewImageInfo', JSON.stringify(previewImageInfo))
    const url = `${process.env.VUE_APP_PREVIEWURL_NEW}`
    const targetWindow = window.open(url, new Date().getTime().toString(), 'height=754, width=1277, top=20, left=20, toolbar=no, menubar=no, scrollbars=no, resizable=yes,location=no, status=no')
    return targetWindow
  }
  if (typeForShow === 'add') {
    const previewImageInfo = {
      previewImageList: imgList,
      params: {},
      characterRecognition: characterRecognition
    }
    previewImageInfo.params.openIndex = indexFlag
    localStorage.setItem('previewImageInfo', JSON.stringify(previewImageInfo))
    const url = `${process.env.VUE_APP_PREVIEWURL_NEW}`
    const targetWindow = window.open(url, new Date().getTime().toString(), 'height=754, width=1277, top=20, left=20, toolbar=no, menubar=no, scrollbars=no, resizable=yes,location=no, status=no')
    return targetWindow
  }
}

// 输入
// "data": [
// {
//   "attachmentCode": "",
//   "certificationName": "",
//   "certificationNumber": "",
//   "certificationType": 0,
//   "dateOfIssue": "",
//   "expireDate": "",
//   "id": 0,
//   "sourceId": 0,
//   "url": ""
// }
// ]
// 返回
// [
//   {
//     'type': 1,
//     urls: [
//     // {
//     //   url: ''
//     // }
//     ],
//     'certName': '药品注册证（许可证）'
//   }
// ]
// 格式化图片
export function formaterimg(imgs) {
  const result = []
  const temp = []
  for (const item of imgs) {
    if (item.url) {
      // 过滤掉url为空的情况
      const img = {}
      img.type = item.certificationType
      img.certName = item.certificationTypeStr
      const imgListSplit = item.url.split(',')
      const imgList = []
      for (const url of imgListSplit) {
        imgList.push({
          url
        })
      }
      img.urls = imgList
      temp.push(img)
    }
  }
  console.error(6.5, result, temp)
  // 相同证照的放在一个对象里面
  for (let index = 1; index < 20; index++) {
    const sameType = []
    for (const item of temp) {
      if (item.type === index) {
        sameType.push(item)
      }
    }
    if (sameType.length > 0) {
      let item = {}
      for (let i = 0; i < sameType.length; i++) {
        if (i === 0) {
          item = sameType[i]
        } else {
          if (item.certName === '') {
            item.certName = sameType[i].certName
          }
          item.urls = item.urls.concat(sameType[i].urls)
        }
      }
      result.push(item)
    }
  }
  console.error(777, result)
  return result
}

// 打开弹窗
// 传参：imgList ,iindex
// indexFlag可以兼容为图片的url
export function openWindow(imgList, indexFlag = 0) {
  // 判断是否有图片
  let allImages = []
  for (const item of imgList) {
    allImages = allImages.concat(item.urls)
  }
  if (allImages.length === 0) {
    // 显示提示
    Message({
      message: '暂无图片可预览',
      type: 'error',
      duration: 5 * 1000
    })
    return
  }
  const previewImageInfo = {
    previewImageList: imgList,
    params: {}
  }
  if (typeof indexFlag === 'number') {
    previewImageInfo.params.openIndex = indexFlag
  } else if (typeof indexFlag === 'string') {
    if (indexFlag === '') {
      // 显示提示
      Message({
        message: '暂无图片可预览',
        type: 'error',
        duration: 5 * 1000
      })
      return
    }
    for (let index = 0; index < allImages.length; index++) {
      const element = allImages[index]
      if (element.url === indexFlag) {
        previewImageInfo.params.openIndex = index
        break
      }
    }
  }
  localStorage.setItem('previewImageInfo', JSON.stringify(previewImageInfo))
  const url = `${process.env.VUE_APP_PREVIEWURL}`
  window.open(url, new Date().getTime().toString(), 'height=754, width=1277, top=20, left=20, toolbar=no, menubar=no, scrollbars=no, resizable=yes,location=no, status=no')
}

// 下载excel模板
export function downloadTemplate(fileName, type = 'doc') {
  let url = ''
  const domain = process.env.VUE_APP_FILE_URL
  url = domain + '/file/' + fileName + '.' + type
  window.open(url)
}

// /* 身份证验证*/
// export const isIdentify = (id) => {
//   // 格式校验
//   const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
//   if (reg.test(id) === false) {
//     return false
//   }

//   // 取身份证前两位,校验省份
//   const vcity = {
//     11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古',
//     21: '辽宁', 22: '吉林', 23: '黑龙江', 31: '上海', 32: '江苏',
//     33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南',
//     42: '湖北', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆',
//     51: '四川', 52: '贵州', 53: '云南', 54: '西藏', 61: '陕西', 62: '甘肃',
//     63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外'
//   }
//   // 台湾身份首字母对应数字
//   const twFirstCode = {
//     A: 10, B: 11, C: 12, D: 13, E: 14, F: 15, G: 16, H: 17, J: 18, K: 19, L: 20,
//     M: 21, N: 22, P: 23, Q: 24, R: 25, S: 26, T: 27, U: 28, V: 29, X: 30, Y: 31,
//     W: 32, Z: 33, I: 34, O: 35
//   }
//   // 香港身份首字母对应的数字
//   const hkFirstCode = {
//     A: 1, B: 2, C: 3, R: 18, U: 21, Z: 26, X: 24, W: 23, O: 15, N: 14
//   }

//   const checkProvince = () => {
//     const province = id.substring(0, 2)
//     // @ts-ignore
//     if (vcity[province] === undefined) {
//       return false
//     }
//     return true
//   }

//   // 检查生日是否正确
//   const checkBirthday = function() {
//     const len = id.length
//     // 身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
//     if (len === '15') {
//       const re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/
//       const arr_data = id.match(re_fifteen)
//       const year = arr_data[2]
//       const month = arr_data[3]
//       const day = arr_data[4]
//       const birthday = new Date('19' + year + '/' + month + '/' + day)
//       return verifyBirthday('19' + year, month, day, birthday)
//     }
//     // 身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
//     if (len === '18') {
//       const re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X|x)$/
//       const arr_data = id.match(re_eighteen)
//       const year = arr_data[2]
//       const month = arr_data[3]
//       const day = arr_data[4]
//       const birthday = new Date(year + '/' + month + '/' + day)
//       return
//     }
//   }
// }

// 防抖
export function VueDebounce(fnName, time) {
  let timeout = null
  return function() {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      this[fnName]()
    }, time)
  }
}

// 处理跳转连接
// 根据pageModule跳转路由
// 带上参数
const pmsMouduleToUrl = {
  'PmsFirstPurchasePendingInfo': '/leyo/first-purchase-pending-info',
  'PmsPurchaseRecord': '/leyo/purchase-record',
  'PmsFirstReplenishTask': '/leyo/first-replenish-task',
  'PmsFirstReplenishGuide': '/leyo/first-replenish-guide',
  'PmsFirstPurchaseManage': '/leyo/first-purchase-manage',
  'PmsFirstPurchaseProgress': '/leyo/first-purchase-progress',
  'PmsGiftActivityLaunch': '/leyo/gift-activity-launch',
  'PmsGiftActivityManagement': '/leyo/gift-activity-management',
  'PmsGroupRebateManagement': '/leyo/group-agreement-receivable',
  'PmsSplitGoodsFillIn': '/leyo/split-goods-fill-in',
  'PmsFirstGiftManagement': '/leyo/first-gift-management'
}
export function handleTargetUrl(params, addParams = {}) {
  console.log('params', params)
  if (typeof (params) === 'string') {
    params = JSON.parse(params)
  }
  params = { ...params, ...addParams }
  if (params.domain === 1) {
    // 跳转自己系统页面
    router.push({
      name: params['pageModule'],
      params: params
    })
  } else {
    let qu = ''
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key]
        qu = qu + `${key}=${element}&`
      }
    }
    qu = qu.substring(0, qu.length - 1)
    params.url = `${pmsUrl}/#${pmsMouduleToUrl[params['pageModule']]}?${qu}`
    console.log('params', params)
    router.push({
      name: params['pageModule'],
      params: params
    })
  }
}

// 输入
// "data": [
// {
// 'certificationType':'',
// "certificationNumber": "",
// "ctime": "",
// "dateOfIssue": "",
// "expireDate": "",
// "id": 0,
// "mtime": "",
// "remark": "",
// "supplierId": 0,
// "url": ""
// }
// ]
// 返回
// [
//   {
//     'type': 1,
//     urls: [
//     // {
//     //   url: ''
//     // }
//     ],
//     'certName': '药品注册证（许可证）'
//   }
// ]
// 供应商管理打开图片预览
// 取消了certificationName字段传入 前端自己添加

// type 0 -  基础证照 1 - 采购证照
export function formaterimgForSupplier(imgs, type) {
  const result = []
  const temp = []
  for (const item of imgs) {
    if (item.url) {
      // 过滤掉url为空的情况
      const img = {}
      img.type = item.certificationType
      if (type === 0) {
        // 基础证照
        img.certName = imgTypeToName[Number(item.certificationType)]
      } else if (type === 1) {
        // 采购证照
        img.certName = imgTypeToNameCustumer[Number(item.certificationType)]
      }
      const imgListSplit = item.url.split(',')
      const imgList = []
      for (const url of imgListSplit) {
        imgList.push({
          url
        })
      }
      img.urls = imgList
      temp.push(img)
    }
  }
  // 相同证照的放在一个对象里面
  const maxTypeIndex = Math.max(
    ...[...Object.keys(imgTypeToName), ...Object.keys(imgTypeToNameCustumer)]
  )
  for (let index = 1; index <= maxTypeIndex; index++) {
    const sameType = []
    for (const item of temp) {
      if (item.type === index) {
        sameType.push(item)
      }
    }
    if (sameType.length > 0) {
      let item = {}
      for (let i = 0; i < sameType.length; i++) {
        if (i === 0) {
          item = sameType[i]
        } else {
          if (item.certName === '') {
            item.certName = sameType[i].certName
          }
          item.urls = item.urls.concat(sameType[i].urls)
        }
      }
      result.push(item)
    }
  }
  return result
}

export function setMonitorInfo(userId, name) {
  window.localStorage.wmUserInfo = JSON.stringify({ userId, userTag: name, projectVersion: '-' })
}

Array.prototype.remove = function(val) {
  const index = this.findIndex(e => e === val)
  if (index !== -1) {
    this.splice(index, 1)
  }
  return this
}

/**
 * 生成七牛云缩略图
 * @param {*} url
 * @returns
 */
export function minimize(url, size = 100) {
  if (typeof url === 'string') {
    return `${url.split('?')[0]}?imageView2/2/w/${size}`
  }
  return url
}

/** 生成图片dataUrl */
export const getDataUrl = (img, base64 = false) => {
  const { width, height } = img
  if (width <= 0 || height <= 0) return
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 1)
  const dataUrl = canvas.toDataURL()
  if (base64) {
    const prefix = dataUrl.split(';base64,')[0]
    const base64Data = dataUrl.substring(prefix.length + ';base64,'.length)
    return base64Data
  }
  return dataUrl
}

export function getImageData(src) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image()
      img.crossOrigin = 'Anonymous'
      img.src = src
      img.onload = () => {
        const dataUrl = getDataUrl(img, true)
        resolve(dataUrl)
      }
      img.onerror = () => {
        reject()
      }
    } catch (err) {
      reject(err)
    }
  })
}
