import { Message, MessageBox } from 'element-ui'
import { Dialog, Toast } from 'vant'
import util from '@/libs/util.js'
import router from '@/router'
import { login, getMenu, logoutOnline } from '@/api/auth'
import { setAside } from '@/utils/index'
import menuAside from '@/menu/aside'
import { cloneDeep } from 'lodash-es'
import store from '@/store/index'
import { initAllCertEnum } from '@/utils/common'
import { goAuthPlatform } from '@/utils/auth'
export default {
  namespaced: true,
  actions: {
    async muteLoginWithToken({ dispatch }, { token = '', phone = '' } = {}) {
      console.log(
        '%c%s',
        'text-decoration: underline; color:#aaa;',
        'silently login token: ',
        token
      )
      if (token) {
        util.cookies.set('token', token)
        await dispatch('getAuth', { matchPhone: phone })
        await dispatch('load')
      }
    },
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login({ dispatch, commit }, { phone = '', password = '' } = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await login({ phone, password })
          util.cookies.set('uuid', data.userId)
          util.cookies.set('token', data.token)
          const { hasJump } = await dispatch('getAuth', { isLogin: true })
          data.hasJump = hasJump
          // 用户登录后从持久化数据加载一系列的设置
          await dispatch('load')
          // 结束
          resolve(data)
        } catch (error) {
          console.log('error :', error)
          reject(error)
        }
      })
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     * @param {Boolean} payload.autoLogin 是否自动登入
     */
    logout(
      { commit, dispatch },
      {
        confirm = false,
        onlineLogout = false,
        redirect, // 此处redirec是作为原login页面的回调redirectPath，入参规范为 route.fullPath
        autoLogin = false
      } = {}
    ) {
      /**
       * @description 注销
       */
      async function logout() {
        try {
          if (onlineLogout) {
            await logoutOnline()
          }
        } catch (error) {
          console.log('error', error)
        }
        // 删除cookie
        util.cookies.remove('token')
        util.cookies.remove('uuid')
        // 清空 vuex 用户信息
        await dispatch('d2admin/user/set', {}, { root: true })
        goAuthPlatform({
          rewrite: redirect,
          blank: !autoLogin
        })
        // 跳转路由
        // if (redirect) {
        //   router.push({
        //     name: 'login',
        //     query: {
        //       redirect
        //     }
        //   })
        // } else {
        //   router.push({
        //     name: 'login'
        //   })
        // }
      }
      // 判断是否需要确认
      if (confirm) {
        commit('d2admin/gray/set', true, { root: true })
        if (store.getters.device === 1) {
          MessageBox.confirm('确定要注销当前用户吗', '注销用户', {
            type: 'warning'
          })
            .then(() => {
              commit('d2admin/gray/set', false, { root: true })
              logout()
            })
            .catch(() => {
              commit('d2admin/gray/set', false, { root: true })
              Message({
                message: '取消注销操作'
              })
            })
        } else {
          Dialog.confirm({
            title: '退出登录',
            message: '确定要退出当前用户吗？'
          })
            .then(() => {
              commit('d2admin/gray/set', false, {
                root: true
              })
              dispatch('d2admin/page/closeAll', '', { root: true })
              logout()
            })
            .catch(() => {
              commit('d2admin/gray/set', false, {
                root: true
              })
              Toast('取消退出登录操作')
            })
        }
      } else {
        logout()
      }
    },
    // 获取权限
    getAuth({ dispatch, commit }, { isLogin = false, jumped = false, matchPhone = '' } = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data: menuData } = await getMenu()
          try {
            initAllCertEnum()
          } catch (err) {
            console.log('init cert enum failed: ', err)
          }
          if (matchPhone) {
            const userPhone = menuData.userInfo.phone
            const shouldReject = matchPhone !== userPhone
            if (shouldReject) {
              Message.error('当前登录账号和PMS账号不一致！')
              goAuthPlatform({ pageUrl: '' })
              return
            }
          }
          // 设置按钮和菜单
          const asidelist = setAside(cloneDeep(menuAside), cloneDeep(menuData.menuList))
          commit('d2admin/menu/asideSet', asidelist, {
            root: true
          })
          let moduleList = []
          for (const outer of menuData.menuList) {
            if (outer.child.length !== 0) {
              for (const inner of outer.child) {
                moduleList.push(inner.module)
                if (inner.child.length !== 0) {
                  for (const gredson of inner.child) {
                    moduleList.push(gredson.module)
                  }
                }
              }
            }
            moduleList.push(outer.module)
          }
          moduleList = moduleList.concat(menuData.buttonModule) // 所有权限配置在一起
          util.cookies.set('uuid', menuData.userInfo.userId)
          // 设置 vuex 用户信息
          await dispatch(
            'd2admin/user/set',
            {
              name: menuData.userInfo.uname,
              buttonModule: menuData.buttonModule,
              phone: menuData.userInfo.phone,
              companyId: menuData.userInfo.companyId, // 0 - 集团公司
              companyName: menuData.userInfo.companyName,
              roleId: menuData.userInfo.roleId,
              ysbProviderId: menuData.userInfo.ysbProviderId,
              asidelist,
              moduleList
            },
            {
              root: true
            }
          )
          let hasJump = jumped
          // 法务角色要去除掉首页
          if (menuData.userInfo.roleId === 25) {
            dispatch('d2admin/page/close', { tagName: '/index' }, { root: true })
            router.push({
              name: 'DeclareContractManagement'
            })
            hasJump = true
          }
          if (!hasJump && isLogin) {
            // 有引入人看板跳转至引入人看板
            if (moduleList.indexOf('introducer_work_manage_dashboard') !== -1) {
              // dispatch('d2admin/page/close', { tagName: '/index' }, { root: true })
              router.push({
                name: 'IntroducerDashboard'
              })
              hasJump = true
            }
          }
          // 结束
          // await dispatch('d2admin/page/closeAll', {}, {
          //   root: true
          // })
          resolve({ hasJump })
        } catch (error) {
          console.log('error :', error)
          reject(error)
        }
      })
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */

    load({ dispatch }) {
      return new Promise(async (resolve) => {
        // DB -> store 加载用户名
        await dispatch('d2admin/user/load', null, { root: true })
        // DB -> store 加载主题
        await dispatch('d2admin/theme/load', null, { root: true })
        // DB -> store 加载页面过渡效果设置
        await dispatch('d2admin/transition/load', null, { root: true })
        // DB -> store 持久化数据加载上次退出时的多页列表
        await dispatch('d2admin/page/openedLoad', null, { root: true })
        // DB -> store 持久化数据加载侧边栏折叠状态
        await dispatch('d2admin/menu/asideCollapseLoad', null, { root: true })
        // DB -> store 持久化数据加载全局尺寸
        await dispatch('d2admin/size/load', null, { root: true })
        // DB -> store 持久化数据加载颜色设置
        await dispatch('d2admin/color/load', null, { root: true })
        // end
        resolve()
      })
    }
  }
}
