import ajax from '@/utils/request'
// 郑炳元 - 获取供应商可编辑的基本证照
export function getSupplierCertEnum(params) {
  return ajax
    .post({
      url: '/supplier/getSupplierCertEnum/v100',
      params,
      type: 2 // 1 本地mock 2 测试服
    })
    .then((res) => {
      return res.data.map((e) => {
        return Object.assign(e, {
          type: e.val,
          name: e.desc
        })
      })
    })
}

// 郑炳元 - 获取供应商可编辑的采购证照
export function getSupplierSaleCertEnum(params) {
  return ajax
    .post({
      url: '/supplier/getSupplierSaleCertEnum/v100',
      params,
      type: 2 // 1 本地mock 2 测试服
    })
    .then((res) => {
      return res.data.map((e) => {
        return Object.assign(e, {
          type: e.val,
          name: e.desc
        })
      })
    })
}

// 郑炳元 - 供应商资料建档 - 新增建档（提交审核）
export function saveSupplier(params) {
  return ajax.post({
    url: '/supplier/saveSupplier/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 已驳回进入编辑-提交审核
export function saveSupplierEdit(params) {
  return ajax.post({
    url: '/supplier/saveSupplierEdit/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 曾迷迷 - 彻底删除基本证照
export function deleteCert(params) {
  return ajax.post({
    url: '/supplier/deleteCert/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 已驳回-获取基本信息（不含采购信息）
export function selectDetail(params) {
  return ajax.post({
    url: '/supplier/selectDetail/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 已驳回-获取采购信息（不含基本信息）
export function getSaleInfo(params) {
  return ajax.post({
    url: '/supplier/selectSalePersonDetail/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 待审核列表
export function selectWaitAuditList(params) {
  return ajax.post({
    url: '/supplier/selectWaitAuditList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 待审核列表导出
export function exportWaitAuditList(params) {
  return ajax.post({
    url: '/supplier/supplierExport/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 郑炳元 - 供应商资料建档 - 待审核列表查看进度
export function viewSupplierProgress(params) {
  return ajax.post({
    url: '/supplier/viewSupplierProgress/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 已拒绝列表
export function rejectList(params) {
  return ajax.post({
    url: '/supplier/selectRejectList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 已拒绝列表导出
export function exportRejectList(params) {
  return ajax.post({
    url: '/supplier/selectRejectListExport/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料建档 - 查看供应商信息
export function viewSupplierInfo(params) {
  return ajax.post({
    url: '/supplier/getSupplierDetail/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 建档待审核列表
export function waitCheckList(params) {
  return ajax.post({
    url: '/supplier/selectAddingAuditList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 建档待审核列表
export function updateCheckList(params) {
  return ajax.post({
    url: '/supplier/selectUpdatingAuditList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 已核准
// 郑炳元 - 供应商资料维护 - 已核准列表
export function selectApprovedList(params) {
  return ajax.post({
    url: '/supplier/selectApprovedList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 已核准导出
export function selectApprovedListExport(params) {
  return ajax.post({
    url: '/supplier/selectApprovedListExport/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 已核准查看证照
export function selectSupplierCertificateAll(params) {
  return ajax.post({
    url: '/supplier/selectSupplierCertificateAll/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 已核准查看审批记录
export function viewSupplierReviewAll(params) {
  return ajax.post({
    url: '/supplier/viewSupplierReviewAll/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 已核准查看编辑-提交审批
export function saveSupplierBasic(params) {
  return ajax.post({
    url: '/supplier/saveSupplierBasic/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 已核准保存采购者信息
export function saveSaleperson(params) {
  return ajax.post({
    url: '/supplier/saveSaleperson/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 已核准查看ERP供应商
export function viewErpSupplier(params) {
  return ajax.post({
    url: '/supplier/viewErpSupplier/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 已核准引入ERP
export function importErp(params) {
  return ajax.post({
    url: '/supplier/importErp/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 切换编辑状态
export function updateForEdit(params) {
  return ajax.post({
    url: '/supplier/updateForEdit/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 建档待审核列表导出
export function selectAddingAuditListExport(params) {
  return ajax.post({
    url: '/supplier/selectAddingAuditListExport/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 资料更新待审核列表导出
export function selectUpdatingAuditListExport(params) {
  return ajax.post({
    url: '/supplier/selectUpdatingAuditListExport/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

/**
 * @description 郑炳元 - 供应商资料维护 - 冻结供应商
 *
 * @param {Object} params
 * @param {number} params.id 供应商id
 * @param {string} params.reason 冻结原因
 * @return {*}
 */
export function freezeSupplier(params) {
  return ajax.post({
    url: '/supplier/freezeSupplier/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

/**
 * @description 郑炳元 - 供应商资料维护 - 重启供应商
 *
 * @param {Object} params
 * @param {number} params.id 供应商id
 * @param {string} params.reason 解冻原因
 * @return {*}
 */
export function reApprove(params) {
  return ajax.post({
    url: '/supplier/reApprove/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料维护 - 审核通过/审核驳回
export function audit(params) {
  return ajax.post({
    url: '/supplier/audit/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑炳元 - 供应商资料管理-公司层审核通过/驳回
export function auditByProvider(params) {
  return ajax.post({
    url: '/supplier/auditByProvider/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 郑炳元 - 供应商资料管理-公司层更新/不更新
export function updateByAuditor(params) {
  return ajax.post({
    url: '/supplier/updateByAuditor/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 郑炳元 - 供应商资料维护 - 返回解锁
export function unLock(params) {
  return ajax.post({
    url: '/supplier/unLockModifyStatus/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 陈毅珊 - 下载证照前获取水印图片
export function getSupplierWmCerts(params) {
  return ajax.post({
    url: '/supplier/getSupplierWmCerts/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 郑柄元 - 供应商资料管理-获取状态列表
export function getSupplierStatus(params) {
  return ajax.post({
    url: '/supplier/getSupplierStatus/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 郑柄元 - 供应商资料管理-获取自动审核配置
export function getAutoAuditSwitch() {
  return ajax.post({
    url: '/supplier/getAutoAuditSwitch/v100',
    type: 2 // 1 本地mock 2 测试服
  })
}
// 郑柄元 - 供应商资料管理-查看首营审批表
export function viewFirstMarketTable(params) {
  return ajax.post({
    url: '/supplier/viewFirstMarketTable/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
/**
 * 郑柄元 - 供应商资料管理-更新自动审核配置
 * @param {object} params 参数
 * @param {number} params.dataChangeAutoReviewStatus 资料变更审核状态
 * @param {number} params.firstBizAutoReviewStatus 首营自动审核状态
 */
export function updateAutoAuditSwitch(params) {
  return ajax.post({
    url: '/supplier/updateAutoAuditSwitch/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

/**
 * 郑柄元 - 供应商资料管理-更新自动审核配置
 * @param {object} params 参数
 * @param {number} supplierId 供应商id
 */
export function getImportedClient(params) {
  return ajax.post({
    url: '/supplier/getImportedClient/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
/**
 * 郑柄元 - 供应商资料管理-模糊搜索供应商
 * @param {object} params 参数
 */
export function getSupplierByName(params) {
  return ajax.post({
    url: '/supplier/getSupplierByName/v100',
    params,
    type: 2
  })
}
/**
 * 曾迷迷 - 获取子公司已绑定/已引入的供应商列表
 * @param {object} params
 */
export function getBindSupplier(params) {
  return ajax.post({
    url: '/supplier/getBindSupplier/v100',
    params,
    type: 2
  })
}

/**
 * 郑柄元 - 判断引入ERP页面是否可编辑返利供应商的勾选
 * @param {object} params
 * @param {number} params.supplierId 供应商Id
 */
export function isAllowEditRebate(params) {
  return ajax.post({
    url: '/supplier/isAllowEditRebate/v100',
    params,
    type: 2
  })
}

/**
 * 郑炳元 - 供应商电子资质搜索列表
 * @param {object} params
 * @returns
 */
export function selectElecQualList(params) {
  return ajax.post({
    url: '/supplier/selectElecQualList/v100',
    params
  })
}

/**
 * 郑炳元 - 供应商电子资质详情
 * @param {object} params
 * @returns
 */
export function getElecQualDetail(params) {
  return ajax.post({
    url: '/supplier/getElecQualDetail/v100',
    params
  })
}

/**
 * 郑炳元 - 供应商通过电子资质更新证照
 * @param {object} params
 * @returns
 */
export function updateCertByElecQual(params) {
  return ajax.post({
    url: '/supplier/updateCertByElecQual/v100',
    params
  })
}

/**
 * 郑炳元 - 供应商废弃电子资质
 * @param {object} params
 * @returns
 */
export function discardElecQual(params) {
  return ajax.post({
    url: '/supplier/discardElecQual/v100',
    params
  })
}

/**
 * 郑炳元 - 供应商删除电子资质
 * @param {object} params
 * @returns
 */
export function deleteElecQual(params) {
  return ajax.post({
    url: '/supplier/deleteElecQual/v100',
    params
  })
}

/**
 * 郑炳元 - 供应商草稿状态-提交审核
 * @param {object} params
 * @returns
 */
export function submitDraftSupplier(params) {
  return ajax.post({
    url: '/supplier/submitDraftSupplier/v100',
    params
  })
}

/**
 * 郑炳元 - 检测供应商是否重复
 * @param {object} params
 * @returns
 */
export function checkSupplierRepeat(params) {
  return ajax.post({
    url: '/supplier/checkSupplierRepeat/v100',
    params
  })
}

/**
 * 郑炳元 - 绑定供应商
 * @param {object} params
 * @returns
 */
export function bindSupplierCode(params) {
  return ajax.post({
    url: '/supplier/bindSupplierCode/v100',
    params
  })
}
/**
 * 郑炳元 - 获取供应商操作按钮权限信息
 * @param {object} params
 * @param {number} params.supplierId 供应商id,新增时传空
 * @param {number} params.tabType tab页的名称 1-已核准-详情页 2-已核准-引入ERP页 3-建档审核页面 4-资料更新审核页面 5-首营待审核页面 6-质量负责人审核页面 7-待审核页面 8-已驳回页面
 * @returns
 */
export function getSupplierOperationAuth(params) {
  return ajax.post({
    url: '/supplier/getSupplierPermission/v100',
    params
  })
}

/**
 * 郑炳元 - 获取供应商审批记录详情
 * @param {*} params
 * @returns
 */
export function getSupplierReviewDetail(params) {
  return ajax.post({
    url: '/supplier/getSupplierReviewChangeInfo/v100',
    params
  })
}

/**
 * cys - 根据供应商编码批量获取供应商信息
 * @param {*} params
 * @returns
 */
export function getSupplierByCodes(params) {
  return ajax.post({
    url: '/supplier/getSupplierByCodes/v100',
    params
  })
}

/**
 * gsm - 获取银行列表
 * @param {*} params
 * @returns Array<{bandName: string, id: number, unionBankNo: string}>
 */
export function getBankList(params) {
  return ajax.post({
    url: '/bank/list/v100',
    params
  })
}

/**
 * zby - 根据乐药码获取税务分类信息
 * @param {*} params { productCode: string }
 * @returns
 */
export function getTaxInfoBySPCode(params) {
  return ajax.post({
    url: '/supplier/getTaxRateInfoByProductCode/v100',
    params
  })
}

/**
 * zby - 保存税务信息
 * @param {*} params
 * {
 *  supplierId: number;
 *  productCodeTaxRequestList: Array<{ productCode: string; taxRate: number }>,
 *  taxClassificationTaxRateRequestList: Array<{taxClassificationCode: string; taxRate: number }>
 * }
 * @returns
 */
export function saveSupplierTaxInfo(params) {
  return ajax.post({
    url: '/supplier/saveTaxInfo/v100',
    params
  })
}

/**
 * zby - 获取编辑记录列表
 * @param {*} params { supplierId: number }
 * @returns
 */
export function getTaxInfoHistory(params) {
  return ajax.post({
    url: '/supplier/getTaxRateHistory/v100',
    params
  })
}

/**
 * 获取编辑记录详情
 * @param {*} params { id: number }
 * @returns
 */
export function getTaxInfoHistoryDetail(params) {
  return ajax.post({
    url: '/supplier/getTaxRateHistoryDetail/v100',
    params
  })
}

/** v5.0.0 供应商首营管理
 * zt - 供应商资料管理-（建档/更新）待审核 -资料保存
 * @param {*} params
 */
export function saveSupplierInfoSuper(params) {
  return ajax.post({
    url: '/supplier/save/v100',
    params
  })
}

/**
 * zt - 供应商自动审核列表
 * @param {*} params
 */
export function supplierReviewSettingList(params) {
  return ajax.post({
    url: '/supplier-review-setting/list/v100',
    params
  })
}

/**
 * zt - 是否开启供应商自动审核
 * @param {*} params
 */
export function supplierReviewSettingChangeStatus(params) {
  return ajax.post({
    url: '/supplier-review-setting/change-status/v100',
    params
  })
}

/** v5.0.0 供应商首营管理
 * @typedef {Object} ParamsSupplierInitialQulaificationList
 * @property {string} area
 * @property {number} beFrozen
 * @property {number} beStoppedPurchasing
 * @property {string} startUpdateTime
 * @property {string} endUpdateTime
 * @property {string} erpSupplierCode
 * @property {string} supplierCode
 * @property {string} supplierName
 * @property {number} supplierType
 * @property {number} tabType
 * @property {number} ysbProviderId
 *
 * @typedef {Object} Pagination
 * @property {number} Pagination.page
 * @property {number} Pagination.pageSize
 */

/**
 *
 * @description cys - 供应商首营管理-资料管理列表
 *
 * @param {ParamsSupplierInitialQulaificationList & Pagination} params
 * @return {*}
 */
export function getSupplierInitialQualList(params) {
  return ajax.post({
    url: '/supplier/first-camp/list/v100',
    params
  })
}

/**
 * @typedef {Object} CountReponse
 * @property {number} firstCampAuditNum 首营待审核
 * @property {number} firstCampPassNum 首营通过
 * @property {number} firstCampRejectNum 首营驳回
 * @property {number} qualityAuditNum 待质量负责人审核
 * @property {number} rebateNum 返利供应商
 * @property {number} updateAuditNum 更新待审核
 * @property {number} unboundNum 已解绑数量
 *
 * @description cys - 供应商首营管理-资料管理列表-获取所有列表的数量
 * @param {ParamsSupplierInitialQulaificationList} params
 * @returns {Promise<{ data: CountReponse}>}
 */
export function getSupplierInitialQulaificationCount(params) {
  return ajax.post({
    url: '/supplier/first-camp/count-list/v100',
    params
  })
}

/**
 * @description cys - 供应商首营管理-资料管理列表-导出前校验
 *
 * @export
 * @param {ParamsSupplierInitialQulaificationList} params
 * @return {*}
 */
export function exportCheckSupplierInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/list-export/check/v100',
    params
  })
}

/**
 * @description cys - 供应商首营管理-资料管理列表-导出
 *
 * @export
 * @param {ParamsSupplierInitialQulaificationList} params
 * @return {*}
 */
export const exportSupplierInitialQualUrl = '/supplier/first-camp/list-export/v100'
/** cys - 供应商首营管理-资料管理列表-返利tab-导出 */
export const exportSupplierInitialQualRebateUrl = '/supplier/first-camp/list-rebate-export/v100'
export function exportSupplierInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/list-export/v100',
    params
  })
}

/**
 * @description cys - 供应商首营管理-供应商详情
 *
 * @export
 * @param {Object} params
 * @param {number} params.id
 * @param {number} params.ysbProviderId
 * @param {number} params.isCurrent // 是否显示历史证照
 * @return {*}
 */
export function getSupplierInitialQualDetail(params) {
  return ajax.post({
    url: '/supplier/first-camp/detail/v100',
    params
  })
}

/**
 * @typedef {Object} ParamsGetSupplierInitialQualPermission
 * @property {number} supplierId
 * @property {number} ysbProviderId
 * @property {number} tabType
 * @description cys - 供应商首营管理-获取详情页权限
 *
 * @export
 * @param {ParamsGetSupplierInitialQualPermission} params
 * @return {*}
 */
export function getSupplierInitialQualPermission(params) {
  return ajax.post({
    url: '/supplier/first-camp/get-permission/v100',
    params
  })
}

/**
 * @typedef {Object} SupplierSaleCertificate 采购证照信息
 * @property {number} id 证照id,存在id时进行更新，不存在时直接插入
 * @property {number} certificationType 证照类型, 1-销售员身份证 2-开户银行 3-其他
 * @property {string} certificationNumber 证书编号
 * @property {string} dateOfIssue 发证日期
 * @property {string} expireDate 有效期至
 * @property {string} url 图片地址
 * @property {string} remark 备注
 * @property {number} isLongTerm 是否为长期 1-是 0-否
 */
/**
 * @typedef {Object} ParamsSupplierInitialQualDto
 * @property {number} supplierId 供应商id
 * @property {number} supplierSalespersonId 供应商采购信息id
 * @property {Array<number>} bizScopeIdList 合作经营范围id
 * @property {string} fileNo 档案编号
 * @property {string} idCardExpireDate 身份证有效期至
 * @property {string} idNumber 销售员身份证号码
 * @property {number} isOnlyRebate 是否引入返利供应商 0-不是 1-是
 * @property {string} phone 销售员电话号码
 * @property {string} salesperson 销售员名称
 * @property {Array<SupplierSaleCertificate>} saveSupplierSaleCertificateRequestList 采购证照信息列表
 */

/**
 * @description - cys - 供应商首营管理-采购信息保存
 * @param {ParamsSupplierInitialQualDto} params
 * @returns
 */
export function saveSupplierInitialQualPurchasingInfo(params) {
  return ajax.post({
    url: '/supplier/first-camp/save-salesperson/v100',
    params
  })
}

/**
 * @typedef {Object} ParamsAuditSupplierInitialQual
 * @property {number} id 供应商id
 * @property {number} isPass 1-通过 0-驳回
 * @property {string} remark 驳回理由
 * @property {number} type 审核记录类型：0-首营审核 1-质量负责人审核
 * @property {ParamsSupplierInitialQualDto} salepersonInfo 供应商采购信息
 *
 * @description - cys - 供应商首营管理-子公司审核
 * @param {ParamsAuditSupplierInitialQual} params
 * @returns
 */
export function auditSupplierInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/audit-by-provider/v100',
    params
  })
}

/**
 * @description - cys- 供应商首营管理-驳回-提交首营审核
 * @param {ParamsSupplierInitialQualDto} params
 * @returns
 */
export function reSubmitSupplierInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/submit-for-audit/v100',
    params
  })
}

/**
 * @typedef {Object} StopOrResumeDto
 * @property {number} supplierId 供应商id
 * @property {number} stopOrResumeFlag 0-恢复采购，1-停采
 * @property {string} reason 原因
 *
 * @description cys - 供应商集团层停采/恢复采购
 * @param {StopOrResumeDto} params
 * @returns
 */
export function stopOrResumePurchase(params) {
  return ajax.post({
    url: '/supplier/stop-resume-purchasing/v100',
    params
  })
}

/**
 * @description cys - 供应商首营管理-首营通过-子公司层停采/恢复采购
 * @param {StopOrResumeDto & {ysbProviderId: number}} params
 * @returns
 */
export function stopOrResumePurchaseInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/stop-resume-purchasing/v100',
    params
  })
}

/**
 * @description cys - 供应商首营管理-首营通过-子公司层冻结/解冻
 * @param {*} params
 * @param {number} params.supplierId
 * @param {number} params.ysbProviderId
 * @param {string} params.reason
 * @param {number} params.freezingOrThawingFlag 0-解冻，1-冻结
 * @returns
 */
export function freezeOrThawingInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/freezing-or-thawing/v100',
    params
  })
}

/**
 * @description zt - 供应商首营管理-更新待审核-更新按钮
 * @param {Object} params
 * @param {number} params.supplierId
 * @param {number} params.ysbProviderId
 * @param {ParamsSupplierInitialQualDto} params.salepersonInfo
 * @returns
 */
export function updateErpCodeData(params) {
  return ajax.post({
    url: '/supplier/first-camp/update-erp-code-data/v100',
    params
  })
}

/**
 * @description zt - 供应商首营管理-更新待审核-更新并生成新编码
 * @param {*} params
 * @param {number} params.supplierId
 * @param {number} params.ysbProviderId
 * @param {ParamsSupplierInitialQualDto} params.salepersonInfo
 * @return {*}
 */
export function generateErpCode(params) {
  return ajax.post({
    url: '/supplier/first-camp/generate-new-erp-code/v100',
    params
  })
}

/**
 * @typedef {Object} ErpCerts
 * @property {string} authDate 发证日期
 * @property {string} certCode 证书编号
 * @property {string} certName 证照名称
 * @property {string} valDate 有效期至
 * @property {number} xuh 序号
 *
 * @description cys - 供应商首营管理-采购详情-查看erp证照按钮
 * @export
 * @param {Object} params
 * @param {number} params.supplierSalespersonId
 * @return {Promise<ErpCerts[]>}
 */
export function getSupplierErpCerts(params) {
  return ajax.post({
    url: '/supplier/first-camp/list-erp-certs/v100',
    params
  })
}

/**
 * @typedef {Object} ParamsUpdateSupplierBasicCertsInitialQual
 * @property {number} supplierSalespersonId
 * @property {Array<SupplierSaleCertificate>} certificateList
 *
 * @description cys - 编辑、删除子公司印章备案表原件、随货同行出库单证照接口
 * @param {ParamsPutSupplierBasicCertsInitialQual} params
 * @returns {*}
 */
export function updateSupplierBasicCertsInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/save-basic-cert/v100',
    params
  })
}

/**
 * @description【陈毅珊】供应商首营管理-首营通过-查看收货证照
 * @param {*} params
 * @param {integer} params.supplierSalespersonId
 * @returns
 */
export function getReceiptCerts(params) {
  return ajax.post({
    url: '/supplier/first-camp/list-receiving-certs/v100',
    params
  })
}

/**
 * @description 【陈毅珊】供应商首营管理-首营通过-保存收货证照
 * @typedef ParamsSaveReceiptCert
 * @property {number} supplierSalespersonId 采购信息id
 * @property {Array<SupplierSaleCertificate>} certificateList
 *
 * @param {ParamsSaveReceiptCert} params
 * @return {*}
 */
export function saveReceiptCert(params) {
  return ajax.post({
    url: '/supplier/first-camp/save-receiving-cert/v100',
    params
  })
}

/**
 * @description 【陈毅珊】供应商首营管理-首营通过-提交审核
 *
 * @param {ParamsSupplierInitialQualDto} params
 * @return {*}
 */
export function submitForAuditInitialQual(params) {
  return ajax.post({
    url: '/supplier/first-camp/save-salesperson-audit/v100',
    params
  })
}
