import layoutHeaderAside from '@/layout/header-aside'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

import cuntomerProfile from './module/customer-profile'
import systemSetting from './module/system-setting'
import productInfo from './module/product-info'
import reportCenter from './module/report-center'
import firstProductReview from './module/first-product-review'
import FirstProductDeclare from './module/first-product-declare'
import declareContract from './module/declare-contract'
import IntroducerDashboard from './module/introducer-dashboard'
import Notice from './module/notice'
import ToDo from './module/to-do'
import FirstProductDetails from './module/first-product-details'
import FirstBatchPurchaseManage from './module/first-batch-manage'
import pms from './module/pms'
import SupplierInfo from './module/supplier-info'
import SaleStatistics from './module/sales-statistics'
import Records from './module/introducer-visit-record'
import GroupPurchase from './module/group-purchase'
import ProductConfig from './module/product-config'
import ProductRecallRoutes from './module/product-recall'

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '首页',
          auth: true,
          cache: true
        },
        component: _import('system/index/page.vue')
      },
      ...cuntomerProfile,
      ...systemSetting,
      ...productInfo,
      ...reportCenter,
      ...firstProductReview,
      ...FirstProductDeclare,
      ...declareContract,
      ...IntroducerDashboard,
      ...Notice,
      ...ToDo,
      ...FirstProductDetails,
      ...FirstBatchPurchaseManage,
      ...pms,
      ...SupplierInfo,
      ...SaleStatistics,
      ...Records,
      ...GroupPurchase,
      ...ProductConfig,
      ...ProductRecallRoutes,
      // 系统 前端日志
      {
        path: 'log',
        name: 'log',
        meta: {
          title: '前端日志',
          auth: true
        },
        component: _import('system/log')
      },
      // 刷新页面 必须保留
      {
        path: 'refresh',
        name: 'refresh',
        hidden: true,
        component: _import('system/function/refresh')
      },
      // 页面重定向 必须保留
      {
        path: 'redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: _import('system/function/redirect')
      }
    ]
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  },
  // {
  //   path: '/change-pwd',
  //   name: 'changePwd',
  //   component: _import('data-center/change-password')
  // },
  {
    path: '/preview',
    name: 'Preview',
    meta: {
      title: '图片预览',
      auth: true
    },
    component: _import('data-center/image-preview/image-preview-old.vue')
  },
  {
    path: '/preview-new',
    name: 'PreviewNew',
    meta: {
      title: '图片预览',
      auth: true
    },
    component: _import('data-center/image-preview/image-preive.vue')
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: _import('system/error/404')
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [...frameIn, ...frameOut, ...errorPage]
