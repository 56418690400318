import { Message } from 'element-ui'
import { getSupplierCertEnum, getSupplierSaleCertEnum } from '@/api/supplier'

// 证照类型和证照名称对应关系
const imgTypeToName = {}
// 利用setInterval,防止请求失败或被取消时导致的错误
// initCertEnum().catch(_ => {
//   const t1 = setInterval(() => {
//     initCertEnum(t1)
//   }, 1000)
// })
export function initCertEnum(interval) {
  return getSupplierCertEnum().then((res) => {
    res.forEach((e) => {
      imgTypeToName[e.type] = e.name
    })
    clearInterval(interval)
  })
}

// 采购信息证照type 和 名称
const imgTypeToNameCustumer = {}
// initSaleCertEnum().catch(_ => {
//   const t1 = setInterval(() => {
//     initSaleCertEnum(t1)
//   }, 1000)
// })
export function initSaleCertEnum(interval) {
  return getSupplierSaleCertEnum().then((res) => {
    res.forEach((e) => {
      imgTypeToNameCustumer[e.type] = e.name
    })
    clearInterval(interval)
  })
}

export function initAllCertEnum() {
  initCertEnum().catch((_) => {
    const t1 = setInterval(() => {
      initCertEnum(t1)
    }, 1000)
  })
  initSaleCertEnum().catch((_) => {
    const t1 = setInterval(() => {
      initSaleCertEnum(t1)
    }, 1000)
  })
}
/**
 * 处理规格中的特殊字符(中-英)
 * @param {String} str
 * @returns String
 */
function formatSpecStr(str = '', reserveSpace) {
  const letters = [
    [/【/g, '['],
    [/】/g, ']'],
    [/！/g, '!'],
    [/，/g, ','],
    [/。/g, '.'],
    [/“|”/g, '"'],
    [/‘/g, "'"],
    [/？/g, '?'],
    [/：/g, ':'],
    [/；/g, ';'],
    [/（/g, '('],
    [/）/g, ')']
  ]
  if (!reserveSpace) {
    letters.push([/\s+/g, ''])
  }
  str = str.trim()
  for (const letter of letters) {
    str = str.replace(letter[0], letter[1])
  }
  return str
}

/**
 * 获取obj指定key的值
 * @param {string} path
 * @param {object} object
 * @returns any
 */
function getValue(path, obj) {
  const paths = path.split('.')
  while (paths.length && obj) {
    const p = paths.shift()
    obj = obj[p]
  }
  return paths.length ? undefined : obj
}
/**
 * 获取数据类型|不考虑自定义类型
 */
function getType(obj) {
  return Object.prototype.toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase()
}
/**
 * 是否对象
 * @param {*} obj
 * @returns
 */
function isObject(obj) {
  return getType(obj) === 'object'
}
/**
 * 是否数组
 * @param {*} arr
 * @returns
 */
function isArray(arr) {
  return getType(arr) === 'array'
}

/**
 * 判断两个对象的某些属性值是否相等
 * @param {Array} keys
 * @param {Object} obj1
 * @param {Object} obj2
 */

function compareObjByKeys(keys, obj1, obj2) {
  let ans = false
  if (isArray(keys) && isObject(obj1) && isObject(obj2)) {
    ans = keys.every((v) => obj1[v] === obj2[v])
    ans = ans && keys.length > 0
  }
  return ans
}

/**
 * 复制-需要浏览器可以访问剪贴板
 * @param {string} text
 * @returns
 */
export function clipboard(text = '', msg = '复制成功！') {
  return new Promise((resolve, reject) => {
    if (!text) {
      resolve('')
      return
    }
    let res = false
    try {
      const el = document.createElement('input')
      el.value = text
      el.style.position = 'absolute'
      el.style.opacity = '0'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, text.length)
      res = document.execCommand('copy')
      document.body.removeChild(el)
    } finally {
      if (res) {
        Message.success(msg)
        resolve(text)
      } else {
        reject('不支持访问剪贴板')
      }
    }
  })
}

export const CommonTag = {
  yes: 1,
  no: 0
}

export const ElTableSortOrder = {
  positive: 'ascending',
  reverse: 'descending'
}

/**
 * Check if the given character is a Chinese character.
 *
 * @param {string} char - The character to be checked
 * @return {boolean} true if the character is a Chinese character, false otherwise
 */
export const isChineseChar = (char) => {
  const regex = /[\u4e00-\u9fa5]/g
  return regex.test(char)
}
export {
  imgTypeToName,
  imgTypeToNameCustumer,
  formatSpecStr,
  getValue,
  getType,
  isObject,
  compareObjByKeys
}
