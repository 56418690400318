












































































































































































































































export default function (Component) {
  Component.options.__source = "src/plugin/layer/layer.vue"
}
