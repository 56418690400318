<template>
  <div>
    <div v-if="total>0 && device===1" class="pagination-container">
      <el-pagination
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :layout="layout"
        :page-sizes="pageSizes"
        :total="total"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <div v-if="total>0 && device===2" class="pagination-container-wap">
      <el-pagination
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        layout="prev, pager, next"
        :total="total"
        v-bind="$attrs"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- <div v-if="total>0" class="pagination-container">
      <el-pagination
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :layout="layout"
        :page-sizes="pageSizes"
        :total="total"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div> -->
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scrollTo'
import { mapGetters } from 'vuex'

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 30, 50, 100, 500, 1000]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([
      'device'
    ]),
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val // 强制设置pageSize
      this.$emit('update:page', 1)
      this.$emit('pagination', { page: 1, pageSize: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, pageSize: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.pagination-container {
  padding: 32px 0px;
  text-align: right;
  overflow-x: auto;
  .el-pagination__total,
  .el-pagination__sizes {
    float: left;
  }
  .pagination-container.hidden {
    display: none;
  }
}
.pagination-container-wap {
  padding: 14px 0px;
  text-align: left;
  overflow-x: auto;
  .el-pagination__total,
  .el-pagination__sizes {
    float: left;
  }
  .pagination-container.hidden {
    display: none;
  }
  .el-pager li:not(.disabled).active{
    // background-color: #4d82ee !important;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/Pagination/index.vue"
}
</vue-filename-injector>
