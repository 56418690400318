<template>
  <div id="app" class="ly-data-center">
    <router-view />
  </div>
</template>

<script>
import util from '@/libs/util'
export default {
  name: 'App',
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created() {
    this.i18nHandle(this.$i18n.locale)
  },
  methods: {
    i18nHandle(val, oldVal) {
      util.cookies.set('lang', val)
      document.querySelector('html').setAttribute('lang', val)
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/style/public-class.scss';
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/App.vue"
}
</vue-filename-injector>
