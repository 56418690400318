const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'first-product-declare',
    name: 'FirstProductDeclare',
    meta: {
      title: '首推商品申报',
      auth: true,
      cache: true
    },
    component: _import('data-center/first-product-declare/first-product-declare.vue')
  },
  {
    path: 'first-product-declare-management',
    name: 'FirstProductDeclareManagement',
    meta: {
      title: '首推商品管理',
      auth: true,
      cache: true
    },
    component: _import('data-center/first-product-declare/first-product-declare.vue')
  },
  {
    path: 'declare-detail',
    name: 'DeclareDetail',
    meta: {
      title: '首推商品新建',
      auth: true
      // cache: true
    },
    component: _import('data-center/first-product-declare/declare-detail.vue')
  },
  {
    path: 'contract-prepare',
    name: 'ContractPrepare',
    meta: {
      title: '首营合同准备',
      auth: true
    },
    component: _import('data-center/first-product-declare/contract-prepare.vue')
  },
  {
    path: 'instructions-sp',
    name: 'InstructionsSp',
    meta: {
      title: '产品规格填写说明',
      auth: true
    },
    component: _import('data-center/first-product-declare/instructions.vue')
  },
  {
    path: 'instructions-name',
    name: 'InstructionsName',
    meta: {
      title: '商品名称填写说明',
      auth: true
    },
    component: _import('data-center/first-product-declare/instructions.vue')
  },
  {
    path: 'first-product-primary-screen',
    name: 'FirstProductPrimaryScreen',
    meta: {
      title: '首推申报初筛',
      auth: true
    },
    component: _import('data-center/first-product-declare/first-product-primary-screen.vue')
  },
  {
    path: 'primary-screen-detail',
    name: 'PrimaryScreenDetail',
    meta: {
      title: '首推申报初筛',
      auth: true
    },
    component: _import('data-center/first-product-declare/primary-screen-detail.vue')
  }
]

export default profile
