import isInIcestark from '@ice/stark-app/lib/isInIcestark'
import { store as iceStore, event as iceEvent } from '@ice/stark-data'
import util from '@/libs/util.js'
import { autoLogin } from '@/api/auth'
import store from '@/store/index'

function checkSign() {
  return iceStore.get('USER_INFO')
}

async function getTokenAnonymous(params) {
  try {
    const { data } = await autoLogin(params)
    util.cookies.set('uuid', data.userId)
    util.cookies.set('token', data.token)
    console.log('get token from data-center : ', data.token)
  } catch (err) {
    throw err
  }
}

async function checkTokenInMicroApp(getMenu, type) {
  console.log('Check token in microapp: ', type)
  if (!isInIcestark()) return
  const userInfo = checkSign()
  const params = userInfo || {}
  try {
    await getTokenAnonymous(params)
    if (getMenu) {
      await store.dispatch(
        'd2admin/account/getAuth',
        { isLogin: true, jumped: true },
        { root: true }
      )
    }
  } catch (err) {
    console.log('emit exit in checkTokenInMicroApp')
    iceEvent.emit('exit')
    throw err
  }
}

export {
  checkSign,
  getTokenAnonymous,
  checkTokenInMicroApp
}
