const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

const records = [
  {
    path: 'introducer-visit-record',
    name: 'IntroducerVisitRecord',
    meta: {
      title: '商务拜访记录',
      auth: true,
      cache: true
    },
    component: _import('data-center/introducer-visit-record/index')
  },
  {
    path: 'introducer-visit-registration',
    name: 'IntroducerVisitRegistration',
    meta: {

      title: '首推商务拜访登记',
      auth: true,
      cache: true
    },
    component: _import('data-center/introducer-visit-record/introducer-visit-registration')
  },
  {
    path: 'introducer-visit-detail',
    name: 'IntroducerVisitDetail',
    meta: {

      title: '首推商务拜访登记',
      auth: true,
      cache: true
    },
    component: _import('data-center/introducer-visit-record/introducer-visit-registration')
  }
]

export default records
