<template>
  <!-- 通过$attrs与$listeners, 将Dialog自身的属性和事件暴露到外部  -->
  <el-dialog :visible.sync="visibleDialog" v-bind="$attrs" class="leyo-dialog" v-on="$listeners">
    <!--内容区域的默认插槽-->
    <slot />
    <!--使用弹框的footer插槽添加按钮-->
    <template #footer text-align="center">
      <!--对外继续暴露footer插槽，有个别弹框按钮需要自定义-->
      <slot name="footer">
        <!--将取消与确定按钮集成到内部-->
        <span>
          <el-button type="primary" @click="$_handleConfirm">
            确 定
          </el-button>
          <el-button @click="$_handleCancel">取 消</el-button>
        </span>
      </slot>
    </template>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // 对外暴露visible属性，用于显示隐藏弹框
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 通过计算属性，对.sync进行转换，外部也可以直接使用visible.sync
    visibleDialog: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    // 对外抛出cancel事件
    $_handleCancel() {
      this.$emit('cancel')
    },
    // 对外抛出 confirm事件
    $_handleConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss">
.leyo-dialog {
  height: auto;
  .el-dialog__footer {
    text-align: center;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/data-center/components/leyo-dialog.vue"
}
</vue-filename-injector>
