import layer from './layer.vue'

const fn = function(Vue) {
  const $layer = Vue.extend(layer)
  let layerId = 2000
  const instanceMap = {}
  const defOpts = {
    title: null, // 标题
    titleStyle: '', // 标题样式，可设置背景色、字体大小等
    component: null, // 要打开的组件
    html: '', // 打开的静态html，优先级比component高
    data: null, // 组件的数据
    draggable: true, // 是否可拖动
    showConfirmBtn: true, // 是否显示确认按钮
    showCancelBtn: true, // 是否显示取消按钮
    showCloseIcon: true, // 是否显示右上的关闭图标
    confirmText: '确定', // 确定按钮文案
    cancelText: '取消', // 取消按钮文案
    confirmBtnStyle: '', // 确定按钮样式
    cancelBtnStyle: '', // 取消按钮样式
    btnWrapperStyle: '' // 按钮父元素的样式
  }
  $layer.setDefaultOpts = function(opt) {
    if (!Object.prototype.toString.call(opt) === '[Object Object]') {
      return console.error('$layer - setDefaultOpts[Function]:参数应为JSON对象')
    }
    Object.assign(defOpts, opt, {})
    return $layer
  }
  $layer.open = function(options) {
    const opts = Object.assign({}, defOpts, options)
    opts.layerId = layerId
    const instanceInject = {
      data: opts,
      html: opts.html || '',
      components: {
        layerContent: opts.component || {}
      }
    }
    const instance = new $layer(instanceInject)
    instance.vm = instance.$mount()
    const $el = instance.vm.$el
    $el.setAttribute('layer-id', opts.layerId)
    $el.style.zIndex = options.zIndex || (layerId + '')
    document.body.appendChild($el)
    instanceMap[layerId] = instance
    layerId++
  }
  $layer.close = function(layerId) {
    if (instanceMap[layerId]) {
      document.body.removeChild(instanceMap[layerId].vm.$el)
      instanceMap[layerId].$destroy()
      delete instanceMap[layerId]
    } else {
      console.error(`不存在对象layerId:${layerId}`)
    }
  }

  return $layer
}

export default fn
