export default {
  namespaced: true,
  state: {
    pasteDataObject: {}
  },
  mutations: {
    // 设置  是否需要修改密码
    setPasteData(state, val) {
      state.pasteDataObject = val
    }
  },
  actions: {
    // 设置客户端型号
    pasteDataSet({
      commit
    }, device) {
      commit('setPasteData', device)
    }
  }
}

