<template>
  <el-dropdown size="small" class="d2-mr">
    <span class="btn-text">{{ userName }}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="logOff">
        <d2-icon name="power-off" class="d2-mr-5" />
        安全退出
      </el-dropdown-item>
      <!-- <el-dropdown-item @click.native="changePWD">
        <d2-icon name="fa-unlock-alt" class="fa-unlock-alt" />
        修改密码
      </el-dropdown-item> -->
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('d2admin/user', ['info']),
    userName() {
      const { companyName = '', name } = this.info
      let res = '未登录'
      if (name) {
        if (companyName) {
          res = `你好 ${companyName}-${name}`
        } else {
          res = `你好 ${name}`
        }
      }
      return res
    }
  },
  created() {},
  methods: {
    ...mapActions('d2admin/account', ['logout']),
    /**
     * @description 登出
     */
    logOff() {
      this.logout({
        confirm: true,
        onlineLogout: true
      })
    },
    changePWD() {
      // this.$router.push({
      //   name: 'changePwd'
      // })
    }
  }
}
</script>
<style lang="scss">
.btn-text {
  color: #ffffff;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>
