import axios from 'axios'
import store from '@/store/index'
import router from '@/router'

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

const API_HOST = 'https://syslog.leyopharm.com'
const API_PWD = 'Leyo2024'
const API_SUBSYSTEM = '数据中台'

/**
 * @description 记录日志
 *
 * @param {object} data
 * @param {string} data.username 用户名
 * @param {string} data.remark 页面名称
 * @return {*}
 */
export const wirteLog = async (params) => {
  const data = {
    ...params,
    pwd: API_PWD,
    subsystem: API_SUBSYSTEM
  }
  axios({
    method: 'post',
    url: `${API_HOST}/web/log/create`,
    data
  }).catch(() => {})
}

router.beforeEach((to, from, next) => {
  try {
    if (!IS_PRODUCTION) return
    const titleList = []
    const { matched = [] } = to
    matched.forEach((route) => {
      const { meta = {} } = route
      const { title } = meta
      title && titleList.push(title)
    })
    if (!titleList.length) return
    const username = store.state.d2admin.user.info.name
    if (!username) return
    const params = {
      username,
      remark: titleList.join('-')
    }
    wirteLog(params).catch(() => {})
  } catch (err) {
    console.log('%c%s', 'font-style: italic; color: #49f6f0;', err)
  } finally {
    next()
  }
})
