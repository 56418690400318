const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'first-batch-manage',
    name: 'FirstBatchPurchaseManage',
    meta: {
      title: '首批购进管理',
      auth: true,
      cache: true
    },
    component: _import('data-center/first-batch-manage/first-batch-manage.vue')
  }
]

export default profile
