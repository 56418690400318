import router from '@/router'
import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store'
import util from '@/libs/util.js'
import { Toast } from 'vant'
import { goAuthPlatform } from '@/utils/auth'
import isInIcestark from '@ice/stark-app/lib/isInIcestark'
import { event as iceEvent } from '@ice/stark-data'
// import packageJson from '@/../package.json'
// const version = packageJson.version

function getBaseUrl() {
  // 作为微前端子项目时，需要固定请求baseURL
  const domain = window.location.hostname
  if (domain.indexOf('datacenter8') !== -1) {
    return process.env.VUE_APP_BASE_API8
  }
  return process.env.VUE_APP_BASE_API
}

// mock请求头
const devMockAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_MOCK,
  timeout: 300000
})

// online请求头
const devOnlineAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_ONLINE,
  timeout: 300000
})

// prod请求头
const prodOnlineAxios = axios.create({
  baseURL: getBaseUrl(),
  timeout: 300000
})
// 不需要token的接口
const anonymousList = ['login']
const isAnonymous = function(url = '') {
  let ans = true
  for (const item of anonymousList) {
    if (url.indexOf(item) !== -1) {
      ans = false
      break
    }
  }
  return !ans
}
// 发出请求
// 返回promise
function request(options, method, extraConfig = {}) {
  // 判断是否已保存token，并在每次请求头部添加token
  // 去除字符串空格
  // for (const key in options.params) {
  //   if (typeof options.params[key] === 'string') options.params[key] = options.params[key].trim()
  // }

  // 没有token则取消请求
  const token = util.cookies.get('token')
  if (!isAnonymous(options.url)) {
    if (!token) {
      return new Promise((resolve, reject) => {
        reject()
      })
    }
  }
  if (!options.params) options.params = {}
  options.params.timestamp = Math.round(new Date().getTime() / 1000).toString()
  options.params.buildTime = process.env.VUE_APP_BUILD_TIME // 构建时间

  // 设置请求头设置
  const axiosOption = {
    method: method || 'post',
    url: options.url,
    responseType: 'json'
  }

  // 根据不同的请求方式封装数据
  switch (axiosOption.method) {
    case 'get':
      axiosOption.params = options.params
      break
    case 'post': {
      axiosOption.data = options.params
      break
    }
    case 'upload': {
      const formData = new FormData()
      for (const key in options.params) {
        formData.append(key, options.params[key])
      }
      axiosOption.data = formData
      if (extraConfig && extraConfig.rewrite) {
        axiosOption.method = extraConfig.rewrite
      }
      break
    }
  }

  // 根据不同环境设施请求头
  let requestAxios = {}
  if (process.env.NODE_ENV === 'production') {
    requestAxios = prodOnlineAxios
  } else {
    if (options.type === 1) {
      requestAxios = devMockAxios
    } else {
      requestAxios = devOnlineAxios
    }
  }
  setInterceptors(requestAxios)
  // 状态码判断
  const SERVERCODE = {
    success: 40001, // 成功
    nologin: 40005, // 未登录
    noPermission: 40006, // 无权限
    overdue: 40007, // token已经过期
    changePassWord: 40008, // 修改密码
    upgrade: 400013, // 强制更新
    tokenOutOfTime: 40029,
    noTokenGive: 40031 // 不支持的认证类型
  }
  return new Promise((resolve, reject) => {
    // 发出请求
    requestAxios
      .request(axiosOption)
      .then(response => {
        const data = response.data
        // 请求正常
        if (response.status === 200) {
          switch (Number(data.code)) {
            case SERVERCODE.success: {
              resolve(data)
              break
            }
            case SERVERCODE.nologin: {
              warnMessage(data.message || '请重新登录')
              router.push('/login')
              reject(data)
              break
            }
            case SERVERCODE.noPermission:
              warnMessage('无权限')
              if (isInIcestark()) {
                console.log('exit in request: no auth')
                iceEvent.emit('exit')
              } else {
                goAuthPlatform({ blank: true })
              }
              break
            case SERVERCODE.tokenOutOfTime:
            case SERVERCODE.overdue: {
              warnMessage('token已经过期')
              store.dispatch('d2admin/account/logout', {
                confirm: false,
                onlineLogout: false,
                autoLogin: true
              })
              break
            }
            case SERVERCODE.upgrade: {
              reject(data)
              warnMessage(data.message || 'error')
              setTimeout(() => {
                window.location.reload()
              }, 2000)
              break
            }
            case SERVERCODE.changePassWord: {
              // reject(data)
              // warnMessage(data.message || '请修改密码')
              setTimeout(() => {
                router.push('/change_password')
              }, 2000)
              break
            }
            case SERVERCODE.noTokenGive:
              break
            default:
              warnMessage(data.message || 'error')
              reject(data)
              break
          }
        } else if (response.status === 404) {
          warnMessage('404 not found')
          reject(data)
        } else {
          warnMessage(data.message || 'error')
          reject(data)
        }
      })
      .catch(error => {
        reject(error.response || error)
      })
  })
}

// get请求
function get(options) {
  return request(options, 'get')
}

// post请求
function post(options) {
  return request(options, 'post')
}

// upload请求
function upload(options, config = {}) {
  return request(options, 'upload', config)
}

// download下载
function download(options) {
  const token = util.cookies.get('token')
  if (!options.params) {
    options.params = {
      token
    }
  }
  if (options.params.token == null) options.params.token = token
  options.params.timestamp = Math.round(new Date().getTime() / 1000).toString()
  return new Promise((resolve, reject) => {
    const axiosOption = {
      method: 'post',
      url: options.url,
      timeout: options.timeout || 60000,
      responseType: 'json',
      cancelToken: options.cancelToken
    }
    axiosOption.data = options.params

    axios(axiosOption)
      .then(response => {
        if (response.status === 200) {
          resolve(response)
        } else {
          warnMessage('操作失败，请重试！')
          reject(response)
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          warnMessage('操作失败，请重试！')
          reject(error)
        }
      })
  })
}

function downloadByStream(options) {
  const token = util.cookies.get('token')
  if (!options.params) {
    options.params = {
      token
    }
  }
  if (options.params.token == null) options.params.token = token
  options.params.timestamp = Math.round(new Date().getTime() / 1000).toString()
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? '/data-center'
      : process.env.VUE_APP_BASE_API_ONLINE
  const headers = {
    Authorization: `Bearer ${token || ''}`
  }
  return new Promise((resolve, reject) => {
    const axiosOption = {
      method: 'post',
      url: options.url,
      baseURL: baseUrl,
      timeout: options.timeout || 300000,
      responseType: 'blob',
      cancelToken: options.cancelToken,
      headers: headers
    }
    axiosOption.data = options.params

    axios(axiosOption)
      .then(response => {
        if (response.status === 200) {
          resolve(response)
        } else {
          warnMessage('操作失败，请重试！')
          reject(response)
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          warnMessage('操作失败，请重试！')
          reject(error)
        }
      })
  })
}

// 设置拦截器
function setInterceptors(val) {
  // 请求拦截器
  val.interceptors.request.use(
    config => {
      // 设置请求头格式
      // post 请求时进行json格式序列化
      config.headers['Content-Type'] = 'application/json'
      if (util.cookies.get('token')) {
        config.headers['Authorization'] =
          'Bearer ' + util.cookies.get('token') || ''
      } else {
        config.headers['Authorization'] = 'Bearer ' + ''
      }
      if (config.method === 'upload') {
        config.method = 'post'
        config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8'
      }
      config.headers['Web-Version'] = process.env.VUE_APP_WEB_VERSION
      config.headers['Build-Time'] = process.env.VUE_APP_BUILD_TIME
      return config
    },
    error => {
      // Do something with request error
      console.log(error) // for debug
      Promise.reject(error)
    }
  )

  // 返回拦截
  val.interceptors.response.use(
    function(response) {
      return response
    },
    function(error) {
      // 对响应错误做处理...
      if (
        error.code === 'ECONNABORTED' &&
        error.message.indexOf('timeout') !== -1
      ) {
        warnMessage('请求超时！')
      }
      return error
    }
  )
}

// 统一处理手机电脑端消息
function warnMessage(txt) {
  if (store.getters.device === 1) {
    Message.error(txt)
  } else {
    Toast.fail(txt)
  }
}
const ajax = {
  get,
  post,
  upload,
  download,
  downloadByStream
}

export default ajax
