










































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/tabs/index.vue"
}
