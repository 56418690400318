const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  {
    path: 'first-product-declare-review',
    name: 'FirstProductReview',
    meta: {
      title: '首推商品审核',
      auth: true,
      cache: true,
      module: 'first_product_declare_review'
    },
    component: _import('data-center/first-product-review/first-product-review.vue')
  },
  {
    path: 'reviewer-price-review',
    name: 'ReviewerPriceReview',
    meta: {
      title: '独家性和价格审核',
      auth: true,
      module: 'reviewer_price_review'
    },
    component: _import('data-center/first-product-review/reviewer-price-review/reviewer-price-review.vue')
  },
  {
    path: 'reviewer-market-review',
    name: 'ReviewerMarketReview',
    meta: {
      title: '市场竞争能力审核',
      auth: true,
      module: 'reviewer_market_review'
    },
    component: _import('data-center/first-product-review/reviewer-market-review/reviewer-market-review.vue')
  },
  {
    path: 'reviewer-final-review',
    name: 'ReviewerFinalReview',
    meta: {
      title: '最终审核',
      auth: true,
      module: 'reviewer_final_review'
    },
    component: _import('data-center/first-product-review/reviewer-final-review/reviewer-final-review.vue')
  },
  {
    path: 'reviewer-suggest-review',
    name: 'ReviewerSuggestReview',
    meta: {
      title: '总评意见',
      auth: true,
      module: 'reviewer_suggest_review'
    },
    component: _import('data-center/first-product-review/reviewer-suggest-review/reviewer-suggest-review.vue')
  },
  {
    path: 'audit-history-show',
    name: 'AuditHistoryShow',
    meta: {
      title: '历史审核记录',
      auth: true
    },
    component: _import('data-center/first-product-review/audit-history-show/audit-history-show.vue')
  },
  {
    path: 'history-all',
    name: 'HistoryAll',
    meta: {
      title: '历史审核记录',
      auth: true
    },
    component: _import('data-center/first-product-review/history-all/history-all.vue')
  },
  {
    path: 'get-and-paste-data',
    name: 'GetAndPasteData',
    meta: {
      auth: true
      // module: 'reviewer_price_review'
    },
    component: _import('data-center/first-product-review/reviewer-price-review/get-and-paste-data.vue')
  }

]

export default profile
