const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'sales-statistics',
    name: 'SalesStatistics',
    meta: {
      title: '首推销售统计',
      auth: true,
      cache: true
    },
    component: _import('data-center/sales-statistics/sales-statistics.vue')
  },
  {
    path: 'sales-statistics-detail',
    name: 'SalesStatisticsDetail',
    meta: {
      title: '详情统计',
      auth: true
      // cache: true
    },
    component: _import('data-center/sales-statistics-detail/sales-statistics-detail.vue')
  }
]

export default profile
