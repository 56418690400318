

































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/layout/header-aside/layout.vue"
}
