(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("NProgress"), require("low"), require("Vuex"), require("ELEMENT"), require("VueRouter"), require("dayjs"), require("Qs"), require("VueI18n"), require("Vue"), require("Sortable"), require("Cookies"), require("LocalStorage"), require("axios"), require("BScroll"), require("UAParser"));
	else if(typeof define === 'function' && define.amd)
		define(["NProgress", "low", "Vuex", "ELEMENT", "VueRouter", "dayjs", "Qs", "VueI18n", "Vue", "Sortable", "Cookies", "LocalStorage", "axios", "BScroll", "UAParser"], factory);
	else if(typeof exports === 'object')
		exports["LeyoDataCenter"] = factory(require("NProgress"), require("low"), require("Vuex"), require("ELEMENT"), require("VueRouter"), require("dayjs"), require("Qs"), require("VueI18n"), require("Vue"), require("Sortable"), require("Cookies"), require("LocalStorage"), require("axios"), require("BScroll"), require("UAParser"));
	else
		root["LeyoDataCenter"] = factory(root["NProgress"], root["low"], root["Vuex"], root["ELEMENT"], root["VueRouter"], root["dayjs"], root["Qs"], root["VueI18n"], root["Vue"], root["Sortable"], root["Cookies"], root["LocalStorage"], root["axios"], root["BScroll"], root["UAParser"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1af2__, __WEBPACK_EXTERNAL_MODULE__5726__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__5f72__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__6e85__, __WEBPACK_EXTERNAL_MODULE__796d__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_a352__, __WEBPACK_EXTERNAL_MODULE_be65__, __WEBPACK_EXTERNAL_MODULE_c758__, __WEBPACK_EXTERNAL_MODULE_cebe__, __WEBPACK_EXTERNAL_MODULE_ec51__, __WEBPACK_EXTERNAL_MODULE_f2ba__) {
return 