import ajax from '@/utils/request'

// 俞孝远 -- 公告列表
export function noticeList(params) {
  return ajax.post({
    url: '/firstProduct/declare/notice/list/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 俞孝远 -- 新增公告
export function noticeAdd(params) {
  return ajax.post({
    url: '/firstProduct/declare/notice/add/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 俞孝远 -- 批量删除公告
export function noticeDeleteBatch(params) {
  return ajax.post({
    url: '/firstProduct/declare/notice/deleteBatch/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 俞孝远 -- 公告详情
export function noticeDetail(params) {
  return ajax.post({
    url: '/firstProduct/declare/notice/detail/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 陈毅珊 -- 发件人
export function senderList(params) {
  return ajax.post({
    url: '/firstProduct/declare/notice/senderList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
