// Vue
import '@babel/polyfill'
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from './i18n'
import App from './App'
// 核心插件
import d2Admin from '@/plugin/d2admin'
import { UTable, UTableColumn } from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css'
// store
import store from '@/store/index'
import ResizeMixin from '@/layout/header-aside/mixins/ResizeHandler'
import auManage from '@/layout/header-aside/mixins/auManage'

// 菜单和路由设置
import router from './router'
// import menuAside from '@/menu/aside'
import { frameInRoutes } from '@/router/routes'

// 图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import './utils/directives'
import { LazyLoad } from './utils/directives'

import '@/assets/style/main.scss'
import '@/assets/svg-icons'
// 微应用相关
import isInIcestark from '@ice/stark-app/lib/isInIcestark'
import setLibraryName from '@ice/stark-app/lib/setLibraryName'
import { checkTokenInMicroApp } from '@/utils/micro-app'
import '@/utils/weblog'

Vue.use(VueCompositionAPI)
Vue.use(LazyLoad)
Vue.use(Viewer)
Vue.use(UTable)
Vue.use(UTableColumn)
Vue.mixin(auManage)

import 'vant/lib/index.css'
import { Field, Button, Dialog, DatetimePicker, Popup, Calendar } from 'vant'
Vue.use(Field)
Vue.use(Button)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Calendar)
Vue.prototype.$dialog = Dialog

// 核心插件
Vue.use(d2Admin)

// 全局组件
import GL_Component from '@/utils/globalComponents'
Vue.use(GL_Component)

function renderContent(el, { fromApp = '' } = {}) {
  const instanceObj = new Vue({
    router,
    store,
    i18n,
    mixins: [ResizeMixin],
    async created() {
      const isMicroApp = isInIcestark()
      let hasJumped = false
      if (isMicroApp) {
        try {
          this.$store.commit('d2admin/page/setFromApp', fromApp)
          this.$store.commit('d2admin/page/setSideVisible', false)
          this.$store.commit('d2admin/page/setHeaderVisible', false)
          // await checkTokenInMicroApp()
          hasJumped = true
        } catch (err) {
          console.log('Failed to init MicroApp: ', err)
        }
      }
      // 处理路由 得到每一级的路由设置
      this.$store.commit('d2admin/page/init', frameInRoutes)
      // 获取登录权限
      this.$store.dispatch(
        'd2admin/account/getAuth',
        { isLogin: isMicroApp, jumped: hasJumped },
        {
          root: true
        }
      )
    },
    mounted() {
      // 展示系统信息
      this.$store.commit('d2admin/releases/versionShow')
      // 用户登录后从数据库加载一系列的设置
      this.$store.dispatch('d2admin/account/load')
      // 获取并记录用户 UA
      this.$store.commit('d2admin/ua/get')
    },
    render: (h) => h(App)
  }).$mount(el)
  return instanceObj
}

if (!isInIcestark()) {
  renderContent('#app')
}
setLibraryName('LeyoDataCenter')

let appInstance
let masterContainer = null
export async function mount(props) {
  try {
    await checkTokenInMicroApp(false, 'login from supplier in mount external')
  } catch (err) {
    // IceEvent.emit('exit')
    return
  }
  const { container, customProps } = props
  appInstance = renderContent(undefined, { fromApp: customProps.fromApp })
  container.innerHTML = ''
  masterContainer = container
  container.appendChild(appInstance.$el)
}

export function unmount() {
  appInstance && appInstance.$destroy()
  if (masterContainer) {
    masterContainer.innerHTML = ''
    masterContainer = null
  }
  console.log('unmount in microapp: ', router)
}
