const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'account-manage',
    name: 'AccountManagement',
    meta: {
      title: '账户管理',
      auth: true
    },
    component: _import('data-center/system-setting/account-manage/account-manage.vue')
  },
  {
    path: 'role-manage',
    name: 'RoleManagement',
    meta: {
      title: '角色管理',
      auth: true
    },
    component: _import('data-center/system-setting/role-manage/role-manage.vue')
  },
  {
    path: 'drug-factory-manager',
    name: 'DrugFactoryManager',
    meta: {
      title: '生产企业维护',
      auth: true
    },
    component: _import('data-center/system-setting/drug-factory-manager/drug-factory-manager.vue')
  },
  {
    path: 'common-name-manager',
    name: 'CommonNameManager',
    meta: {
      title: '商品通用名维护',
      auth: true
    },
    component: _import('data-center/system-setting/common-name-manage/common-name-manage.vue')
  },
  {
    path: 'first-product-rules',
    name: 'FirstProductRules',
    meta: {
      title: '首推规则设置',
      auth: true
    },
    component: _import('data-center/system-setting/first-product-rules-manage/add-primary-screen-rules.vue')
  },
  {
    path: 'first-product-tags',
    name: 'FirstProductTags',
    meta: {
      title: '首推商品设置',
      auth: true
    },
    component: _import('data-center/system-setting/first-product-tags/index.vue')
  },
  {
    path: '/product-brand-manager',
    name: 'BrandManager',
    meta: {
      title: '商品品牌维护',
      auth: true
    },
    component: _import('data-center/system-setting/brand-manage/index.vue')
  },
  {
    path: '/cashcow-buyer-management',
    name: 'CashcowBuyerManagement',
    meta: {
      title: '摇钱树采购员维护'
      // auth: true
    },
    component: _import('data-center/system-setting/cashcow-buyer-manage/index.vue')
  }
]

export default profile
