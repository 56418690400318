var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.device===1)?_c('div',{staticClass:"d2-layout-header-aside-group",class:{grayMode: _vm.grayActive},style:(_vm.styleLayoutMainGroup)},[_c('div',{staticClass:"d2-layout-header-aside-mask"}),_c('div',{staticClass:"d2-layout-header-aside-content",class:{'iframe-test':_vm.VUE_APP_TITLE_WARMING},attrs:{"flex":"dir:top"}},[(_vm.headerVisible)?_c('div',{staticClass:"d2-theme-header head-self",class:{'redBackgroung':_vm.VUE_APP_TITLE_WARMING},style:({
        // eslint-disable-next-line vue/this-in-template
        opacity: this.searchActive ? 0.5 : 1
      }),attrs:{"flex-box":"0","flex":""}},[_c('div',{staticClass:"logo-group-title",attrs:{"flex-box":1}},[_c('img',{attrs:{"src":require("../../assets/image/index/logo.png")}}),_vm._v(" 乐药基础数据中心 ")]),_c('div',{staticClass:"d2-header-right",attrs:{"flex-box":"0"}},[(_vm.VUE_APP_TITLE_WARMING)?_c('div',{staticClass:"warning-test"},[_vm._v("测试服环境！！！！！！！！！！")]):_vm._e(),_c('d2-header-user')],1)]):_vm._e(),_c('div',{staticClass:"d2-theme-container",attrs:{"flex-box":"1","flex":""}},[(_vm.sidebarVisible)?_c('div',{ref:"aside",staticClass:"d2-theme-container-aside",style:({
          width: _vm.asideCollapse ? _vm.asideWidthCollapse : _vm.asideWidth,
          // eslint-disable-next-line vue/this-in-template
          opacity: this.searchActive ? 0.5 : 1
        }),attrs:{"flex-box":"0"}},[_c('d2-menu-side')],1):_vm._e(),_c('div',{staticClass:"d2-theme-container-main",attrs:{"flex-box":"1","flex":""}},[(!_vm.searchActive)?_c('div',{staticClass:"d2-theme-container-main-layer",attrs:{"flex":"dir:top"}},[(_vm.sidebarVisible)?_c('div',{staticClass:"d2-theme-container-main-header",attrs:{"flex-box":"0"}},[_c('d2-tabs')],1):_vm._e(),(_vm.pageType === 1 && _vm.headerVisible)?_c('warning'):_vm._e(),_c('div',{staticClass:"d2-theme-container-main-body",attrs:{"flex-box":"1"}},[_c('keep-alive',{attrs:{"include":_vm.keepAlive}},[_c('router-view')],1)],1),_c('AppFooter')],1):_vm._e()])])]),_c('rightBottom')],1):_c('div',{staticClass:"d2-layout-header-aside-group wap",class:{grayMode: _vm.grayActive},style:(_vm.styleLayoutMainGroup)},[_c('div',{staticClass:"d2-layout-header-aside-mask"}),_c('div',{staticClass:"d2-layout-header-aside-content wap",attrs:{"flex":"dir:top"}},[_c('div',{staticClass:"d2-theme-header",style:({
        // eslint-disable-next-line vue/this-in-template
        opacity: this.searchActive ? 0.5 : 1
      }),attrs:{"flex-box":"0","flex":""}},[_c('div',{staticClass:"logo-group-title",attrs:{"flex-box":0}},[_c('Hamburger',{attrs:{"is-active":_vm.hasAside},on:{"toggleClick":_vm.handleHasAside}})],1),_c('div',{staticClass:"d2-header-right",attrs:{"flex-box":"1"}},[_c('div',{staticClass:"bread-wap"},[_vm._v(" "+_vm._s(_vm.bread)+" ")]),_c('d2-header-user',{attrs:{"id":"header"}})],1)]),_c('div',{staticClass:"tabs-wrap"},[_c('d2-tabs')],1),_c('div',{staticClass:"d2-theme-container",class:{'noBread':_vm.device === 2},attrs:{"flex-box":"1","flex":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAside),expression:"hasAside"}],ref:"aside",staticClass:"d2-theme-container-aside",style:({
          width: _vm.asideCollapse ? _vm.asideWidthCollapse : _vm.asideWidth,
          // eslint-disable-next-line vue/this-in-template
          opacity: this.searchActive ? 0.5 : 1
        }),attrs:{"flex-box":"0"}},[_c('d2-menu-side'),_c('div',{staticClass:"menu-mask",on:{"click":_vm.handleHasAside}})],1),_c('div',{staticClass:"d2-theme-container-main",attrs:{"flex-box":"1","flex":""}},[(!_vm.searchActive)?_c('div',{staticClass:"d2-theme-container-main-layer",attrs:{"flex":"dir:top"}},[_c('div',{staticClass:"d2-theme-container-main-body",attrs:{"flex-box":"1"}},[_c('keep-alive',{attrs:{"include":_vm.keepAlive}},[_c('router-view')],1)],1)]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }