
























































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/data-center/notice/components/notice-details.vue"
}
