





































































































export default function (Component) {
  Component.options.__source = "src/components/TableSelected/TableSelected.vue"
}
