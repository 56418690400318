import ajax from '@/utils/request'

// 品种申报数据 ss
export function declareData(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/declareData/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 在库数据 ss
export function stockData(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/stockData/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 月销售数据 ss
export function monthSaleData(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/monthSaleData/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 近30天动销数据 ss
export function activeSaleData(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/activeSaleData/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 待办事项列表(看板) xy
export function todoList(params) {
  return ajax.post({
    url: '/firstProduct/declare/todoList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 已办事项列表(看板) xy
export function doneList(params) {
  return ajax.post({
    url: '/firstProduct/declare/doneList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 处理待办事项 xy
export function handleToDoList(params) {
  return ajax.post({
    url: '/firstProduct/declare/handleToDoList/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 最新事务进度统计 wh
export function newestProgressCount(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/newestProgressCount/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 每日提醒 xy
export function todoListOnLive(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/todoListOnLive/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 签名获取 wh
export function getOuterSign(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/getOuterSign/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 试销列表-移动端 wh
export function trySaleListMobile(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/trySaleListMobile/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 引入甄选列表-移动端 wh
export function introduceProgressListMobile(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/introduceProgressListMobile/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
// 在售列表-移动端 wh
export function onSaleListMobile(params) {
  return ajax.post({
    url: '/firstProduct/declare/introducer/dashboard/onSaleListMobile/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}
