<template>
  <div v-if="device===1" class="notice-details">
    <el-dialog
      class="el-dialog-wrap"
      :title="title"
      :visible.sync="visibleForAdd"
    >
      <el-card shadow="always" class="el-card-show">
        <div class="line">
          <div class="title">发件人</div>
          <div class="send-input">
            {{ createUserName }}
          </div>
        </div>
      </el-card>
      <div style="height:10px;" />
      <el-card shadow="always" class="el-card-show">
        <div class="line">
          <div class="title">通知时间</div>
          <div class="long-input">
            {{ ctime }}
          </div>
        </div>
      </el-card>
      <div style="height:10px;" />
      <div class="tips">
        <div class="line">
          <div class="title">公告内容</div>
          <div class="long-input">
            {{ content }}
          </div>
        </div>
      </div>
      <div style="height:10px;" />
      <el-card shadow="always" class="el-card-show">
        <div class="line">
          <div class="title">附件</div>
          <div class="long-input">
            <div v-for="(item,index) in fileList" :key="index">
              <div class="attachment">
                <div>{{ item.fileName }}</div>
                <div class="download" @click="downloadAttachment(item)">下载</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <div style="height:10px;" />
    </el-dialog>
  </div>
  <div v-else class="notice-details-wap">
    <el-dialog
      class="el-dialog-wrap"
      :title="title"
      :visible.sync="visibleForAdd"
    >
      <el-card shadow="always" class="el-card-show">
        <div class="line">
          <div class="title">发件人</div>
          <div class="send-input">
            {{ createUserName }}
          </div>
        </div>
      </el-card>
      <div style="height:10px;" />
      <el-card shadow="always" class="el-card-show">
        <div class="line">
          <div class="title">通知时间</div>
          <div class="long-input">
            {{ ctime }}
          </div>
        </div>
      </el-card>
      <div style="height:10px;" />
      <div class="tips">
        <div class="line">
          <div class="title">公告内容</div>
          <div class="long-input">
            {{ content }}
          </div>
        </div>
      </div>
      <div style="height:10px;" />
      <el-card shadow="always" class="el-card-show">
        <div class="line">
          <div class="title">附件</div>
          <div class="long-input">
            <div v-for="(item,index) in fileList" :key="index">
              <div class="attachment">
                <div>{{ item.fileName }}</div>
                <div class="download" @click="downloadAttachment(item)">下载</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <div style="height:10px;" />
    </el-dialog>
  </div>
</template>
<script>
import {
  noticeDetail
} from '@/api/notice'
import { mapGetters } from 'vuex'
export default {
  name: 'DialogForAdd',
  props: {

    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fileList: [],
      content: '',
      createUserName: '',
      ctime: '',
      title: '',
      visibleForAdd: false
    }
  },
  computed: {
    ...mapGetters([
      'device'
    ])
  },
  watch: {
    // id(val) {
    //   this.getDetail(val)
    // },
    visible(val) {
      if (!val) {
        this.content = ''
        this.title = ''
        this.fileList = []
        this.noticeRoleIds = []
        this.fileListForSubmit = []
      } else {
        this.getDetail(this.id)
      }
    },
    visibleForAdd(val) {
      this.$emit('update:visible', val)
    }
  },
  created() {
  },
  methods: {
    downloadAttachment(val) {
      window.open(val.fileUrl)
    },
    async getDetail(val) {
      this.visibleForAdd = true
      try {
        const params = {
          id: val
        }
        const { data } = await noticeDetail(params)

        this.fileList = data.fileList
        this.content = data.content
        this.createUserName = data.createUserName
        this.ctime = data.ctime
        this.title = data.title
      } catch (error) {
        console.log('error :>> ', error)
        // 查询接口出错则关闭弹窗
        this.$emit('update:visible', false)
      }
    },

    handleCancle() {
      this.visibleForAdd = false
      this.fileList = []
      this.content = ''
      this.createUserName = ''
      this.ctime = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.notice-details-wap{
  .line{
     display: flex;
     //margin-top: 10px;
   }
   .title{
     width: 100px;
     //padding-left: 50px;
     //margin-right: 5px;
   }
     .content{
         width: 450px;
     }
     .long-input{
        width: 450px;
      }
     .attachment{
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         .download{
             color: #fff;
             margin-left: 26px;
             background-color: #409EFF;
             padding: 5px 10px;
             border-radius: 20px;
             cursor: pointer;
         }
     }
   .button-for-confirm{
     display: flex;
     justify-content: center;
   }
        .el-dialog-for-el-form{
            display: flex;
            flex-direction: column;
             .el-form-item-button{
                display: flex;
                justify-content: center;
            }
        }
        .tip-for-password{
            margin-left: 110px;
            margin-bottom: 4px;
            font-size: 12px;
            color: #b9b5b5;
            margin-top: -10px;
        }
}
 .notice-details{
   .line{
     display: flex;
     //margin-top: 10px;
   }
   .title{
     width: 100px;
     //padding-left: 50px;
     //margin-right: 5px;
   }
     .content{
         width: 450px;
     }
     .long-input{
        width: 450px;
      }
     .attachment{
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         .download{
             color: #fff;
             margin-left: 26px;
             background-color: #409EFF;
             padding: 5px 10px;
             border-radius: 20px;
             cursor: pointer;
         }
     }
   .button-for-confirm{
     display: flex;
     justify-content: center;
   }
        .el-dialog-for-el-form{
            display: flex;
            flex-direction: column;
             .el-form-item-button{
                display: flex;
                justify-content: center;
            }
        }
        .tip-for-password{
            margin-left: 110px;
            margin-bottom: 4px;
            font-size: 12px;
            color: #b9b5b5;
            margin-top: -10px;
        }
    }
</style>
<style lang="scss">
.notice-details-wap{
  .el-dialog{
     width: 90%;
  }
  .tips{
          padding: 20px;
          background-color: #fff5dd;
          color: #a04d10;
          border-radius: 6px;
          font-size: 14px;
          box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%)
        }
}
.notice-details{
        .tips{
          padding: 20px;
          background-color: #fff5dd;
          color: #a04d10;
          border-radius: 6px;
          font-size: 14px;
          box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%)
        }
        .el-dialog{
        width: 600px;
      }
      .el-input__inner{
        width: 550px;
      }
      .el-textarea__inner{
          width: 550px;
      }
      .select-input{
         .el-input__inner{
            width: 270px;
        }
      }
      .el-dialog__body{
        padding: 10px 20px;
      }
      .el-form-item{
        margin-bottom: 10px;
      }
      .send-input{
        .el-input__inner{
            width: 270px;
        }
      }
      .long-input{
        .el-input__inner{
            width: 550px;
        }
      }
    }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/data-center/notice/components/notice-details.vue"
}
</vue-filename-injector>
