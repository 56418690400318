






























































export default function (Component) {
  Component.options.__source = "src/views/data-center/components/leyo-dialog.vue"
}
