const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'first-purchase-pending-info',
    name: 'PmsFirstPurchasePendingInfo',
    meta: {
      title: '首批购进信息',
      auth: true

    },
    component: _import('data-center/pms/PmsFirstPurchasePendingInfo.vue')
  },
  {
    path: 'purchase-record',
    name: 'PmsPurchaseRecord',
    meta: {
      title: '采购记录',
      auth: true

    },
    component: _import('data-center/pms/PmsPurchaseRecord.vue')
  },
  {
    path: 'first-replenish-task',
    name: 'PmsFirstReplenishTask',
    meta: {
      title: '首推补货任务',
      auth: true

    },
    component: _import('data-center/pms/PmsFirstReplenishTask.vue')
  },
  {
    path: 'first-purchase-manage',
    name: 'PmsFirstPurchaseManage',
    meta: {
      title: '首推购进管理',
      auth: true

    },
    component: _import('data-center/pms/PmsFirstPurchaseManage.vue')
  },
  {
    path: 'first-replenish-guide',
    name: 'PmsFirstReplenishGuide',
    meta: {
      title: '首推补货指引',
      auth: true

    },
    component: _import('data-center/pms/PmsFirstReplenishGuide.vue')
  },
  {
    path: 'first-purchase-progress',
    name: 'PmsFirstPurchaseProgress',
    meta: {
      title: '查看进度',
      auth: true

    },
    component: _import('data-center/pms/PmsFirstPurchaseProgress.vue')
  },
  {
    path: 'buy_and_give',
    name: 'PmsGiftActivityLaunch',
    meta: {
      title: '买赠活动发起',
      auth: true

    },
    component: _import('data-center/pms/PmsGiftActivityLaunch.vue')
  },
  {
    path: 'gift-activity-managements',
    name: 'PmsGiftActivityManagement',
    meta: {
      title: '买赠活动管理',
      auth: true
    },
    component: _import('data-center/pms/PmsGiftActivityManagement.vue')
  },
  {
    path: 'group-agreement-receivable',
    name: 'PmsGroupRebateManagement',
    meta: {
      title: '集团协议应收',
      auth: true
    },
    component: _import('data-center/pms/PmsGroupRebateManagement.vue')
  },
  {
    path: 'split-goods-fill-in',
    name: 'PmsSplitGoodsFillIn',
    meta: {
      title: '分货信息填写',
      auth: true
    },
    component: _import('data-center/pms/PmsSplitGoodsFillIn.vue')
  },
  {
    path: 'first-gift-management',
    name: 'PmsFirstGiftManagement',
    meta: {
      title: '首推赠品管理',
      auth: true
    },
    component: _import('data-center/pms/PmsFirstGiftManagement.vue')
  }
]

export default profile
