const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'review-statistic-report',
    name: 'ReviewStatisticReport',
    meta: {
      title: '审核数统计报表',
      auth: true
    },
    component: _import('data-center/review-statistic-report/review-statistic-report.vue')
  }
]

export default profile
