<template>
  <el-col class="app-footer">
    <div @click="footerClick">
      ©  广州乐药信息科技有限公司   粤ICP备19151693号
    </div>
  </el-col>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {
    footerClick() {
      window.location.href = 'https://beian.miit.gov.cn/'
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-footer {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  color: #909399;
  font-size: 12px;
  cursor: pointer;
  background-color: #f6f6f6;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/AppFooter.vue"
}
</vue-filename-injector>
