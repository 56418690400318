const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

const ProductRecallRoutes = [
  {
    path: '/product-recall',
    redirect: '/product-recall/maintain'
  },
  {
    path: '/product-recall/maintain',
    name: 'ProductRecallMaintain',
    meta: {
      title: '召回药品信息维护',
      auth: true,
      cache: true,
      keepAlive: true
    },
    component: _import('data-center/product-recall/product-recall-maintain/index.vue')
  },
  {
    path: '/product-recall/search',
    name: 'ProductRecallSearch',
    meta: {
      title: '召回药品信息查询',
      auth: true,
      cache: true,
      keepAlive: true
    },
    component: _import('data-center/product-recall/product-recall-search/index.vue')
  }
]

export default ProductRecallRoutes
