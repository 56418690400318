// 需要全局注册的组件
import LeyoDialog from '@/views/data-center/components/leyo-dialog' // 内嵌了取消和确定按钮的el-dialog组件

const plugins = {
  // 注册
  install(Vue) {
    Vue.component('LeyoDialog', LeyoDialog) // 第一个参数:组件名称,第二个参数:要注册的组件
  }
}
export default plugins
