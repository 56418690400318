<script>
export default {
  name: 'Render',
  functional: true,
  props: {
    scope: {
      type: Object,
      default: () => {}
    },
    render: {
      type: Function,
      default: null
    }
  },
  render: (h, ctx) => {
    return ctx.props.render ? ctx.props.render(h, ctx.props.scope) : ''
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/List/render.vue"
}
</vue-filename-injector>
