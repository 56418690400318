import layer from './layer.js'
layer.install = function(Vue, defaultOpts = {}) {
  Vue.prototype.$layer = layer(Vue).setDefaultOpts(defaultOpts)
}
export default layer

/**
 * 新增：
 * 支持弹窗拖动;
 * 设置默认配置,如标题颜色为蓝色：Vue.use(layer, { titleStyle:'color:Blue'})
 */

// 函数时调用弹出框，用法参考：
// 要弹出的组件
// test.vue：
// <template>
//   <div >
//     {{data.hello}}
//   </div>
// </template>

// <script>
// export default {
//   created() {
//   },
//   methods: {
//   }
// }
// </script>

// 页面中调用
// this.$layer.open({
//     title: 'xxxx',
//     data: {
//         hello: "helloMan",
//     },
//     component: test,
//     // 点击确定
//     yes: function (layerId, data) {
//         this.$layer.close(layerId);
//     }
// });

//  下面是出现的一些问题修复方法：
// 一：在组件A中打开组件B，而组件B中引入了组件A,导致循环。报错
// Unknown custom element: <departmentTree> - did you register the component correctly?
//  For recursive components, make sure to provide the "name" option.
// 问题代码
// import departmentTree from '@/views/system/index/components/departmentTree.vue'
// export default {
//   name: 'CheckMember',
//   components: {
//     departmentTree:departmentTree
//   },
// }
// 解决方案 :懒加载组件
// export default {
//   name: 'CheckMember',
//   components: {
//     departmentTree:() => import('@/views/system/index/components/departmentTree.vue'),
//   },
// }
