export default {
  namespaced: true,
  state: {
    // 1-电脑 2-手机
    device: 1
  },
  mutations: {
    // 设置  是否需要修改密码
    setDevice(state, val) {
      state.device = val
    }
  },
  actions: {
    // 设置客户端型号
    deviceSet({
      commit
    }, device) {
      commit('setDevice', device)
    }
  }
}

