const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'introducer-work-manage-notice',
    name: 'IntroducerWorkManageNotice',
    meta: {
      title: '公告',
      auth: true
    },
    component: _import('data-center/notice/notice.vue')
  }
]

export default profile
