import Vue from 'vue'
import VueRouter from 'vue-router'

// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import isInIcestark from '@ice/stark-app/lib/isInIcestark'
import getBasename from '@ice/stark-app/lib/getBasename'

import store from '@/store/index'
import util from '@/libs/util.js'

// 路由数据
import routes from './routes'

import { checkTokenInMicroApp } from '@/utils/micro-app'
import { goAuthPlatform } from '@/utils/auth'

const doNotOpenTabPage = [
  'FirstProductInfoDetails',
  'FirstProductInfoDetailsNew',
  'CommodityTaxRateReviewGroupDetail',
  'CommodityTaxRateReviewCompanyDetail',
  'CustomerInfoSetDetail',
  'ProductInfoDetail',
  'ProductInfoDetailNew',
  'ContractPrepare',
  'AuditHistoryShow',
  'ReviewerPriceReview',
  'ReviewerMarketReview',
  'ReviewerFinalReview',
  'ReviewerSuggestReview',
  'DeclareDetail',
  'HistoryAll',
  'GetAndPasteData',
  'EditSupplierInfo',
  'EditSupplierInfoNew',
  'SupplierInfoManageDetail',
  'SalesStatisticsDetail',
  'FirstProductPrimaryScreen',
  'PrimaryScreenDetail',
  'IntroducerVisitRegistration',
  'IntroducerVisitDetail',
  'productCodeDetail',
  'viewEditRecord',
  'productCodeReplace',
  'GroupPurchaseSetting',
  'CustomerInitialQualificationDetail'
] // 不打开标签的页面

// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch((err) => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

if (isInIcestark()) {
  console.log(
    '%c%s%s',
    'color: #f50605; font-size: 20px; text-style: italic;',
    'Base path:',
    getBasename()
  )
}
function getFilterRoutes(excludePaths = [], list, prefix = '') {
  if (!Array.isArray(list) || !Array.isArray(excludePaths)) return []
  const pathsSet = new Set(excludePaths)
  return list.filter((routeInstance) => {
    const { path, children } = routeInstance
    let childrenNew = []
    const fullPath = prefix + path
    if (children) {
      childrenNew = getFilterRoutes(excludePaths, children, fullPath)
    }
    routeInstance.children = childrenNew
    return pathsSet.has(fullPath) || childrenNew.length
  })
}
const routesList = isInIcestark()
  ? getFilterRoutes(['/product-info-detail-new', '/login'], routes)
  : routes
// 导出路由 在 main.js 里使用
const router = new VueRouter({
  routes: routesList,
  base: isInIcestark() ? getBasename() : '/'
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  let replace = false
  if (to.query.token) {
    // util.cookies.set('token', to.query.token)
    const decodedToken = window.atob(window.atob(to.query.token))
    await store.dispatch('d2admin/account/muteLoginWithToken', {
      token: decodedToken,
      phone: to.query.phone
    })
    replace = true
  }
  // 确认已经加载多标签页数据 https://github.com/d2-projects/d2-admin/issues/201
  await store.dispatch('d2admin/page/isLoaded')
  // 确认已经加载组件尺寸设置 https://github.com/d2-projects/d2-admin/issues/198
  await store.dispatch('d2admin/size/isLoaded')
  // 进度条
  NProgress.start()
  // analystics
  try {
    const isRaw = !isInIcestark()
    const analysticsEnable = window._hmt && to.path
    if (isRaw && analysticsEnable) {
      window._hmt.push(['_trackPageview', '/#' + to.path])
    }
  } catch (err) {
    console.log('百度统计：', err)
  }
  // 关闭搜索面板
  store.commit('d2admin/search/set', false)
  // 验证当前路由所有的匹配中是否需要有登录验证的
  if (to.matched.some((r) => r.meta.auth)) {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    // 请根据自身业务需要修改
    const isMicroApp = isInIcestark()
    if (isMicroApp) {
      // 作为子应用时，不跳转登录页，且每次都静默登录，刷新token
      try {
        await checkTokenInMicroApp(true)
        next()
      } catch (err) {
        console.log('%c%s', 'color: #ff0a0b;', 'Failed to get token: ', err)
      }
    } else {
      const token = util.cookies.get('token')
      if (token && token !== 'undefined') {
        if (replace) {
          const routerConfig = {
            ...to,
            replace
          }
          const routePath = routerConfig.fullPath
          const replacer = /&token=[^&]*/
          routerConfig.fullPath = routePath.replace(replacer, '')
          delete routerConfig.query.token
          next(routerConfig)
        } else {
          next()
        }
      } else {
        // next({
        //   name: 'login',
        //   query: {
        //     redirect: to.fullPath
        //   }
        // })
        goAuthPlatform({ rewrite: to.fullPath, blank: false })
      }
    }
  } else {
    // 不需要身份校验 直接通过
    next()
  }
})

router.afterEach((to) => {
  // 进度条
  NProgress.done()
  // 多页控制 打开新的页面
  const index = doNotOpenTabPage.indexOf(to.name)
  if (index === -1) {
    store.dispatch('d2admin/page/open', to)
  }
  // 更改标题
  util.title(to.meta.title)
})

export default router
