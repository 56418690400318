const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  {
    path: 'supplier-info-set',
    name: 'SupplierInfoSet',
    meta: {
      title: '供应商资料建档',
      auth: true,
      cache: true
    },
    component: _import('data-center/supplier-info/supplier-info-set')
  },
  {
    path: 'edit-supplier-info-new',
    name: 'EditSupplierInfoNew',
    meta: {
      title: '供应商资料',
      auth: true
    },
    component: _import('data-center/supplier-info/supplier-info-set/edit-supplier-info-new')
  },
  {
    path: 'supplier-info-manage',
    name: 'SupplierInfoManage',
    meta: {
      title: '供应商资料管理',
      auth: true,
      cache: true
    },
    component: _import('data-center/supplier-info/supplier-info-manage')
  },
  {
    path: 'supplier-info-manage-detail',
    name: 'SupplierInfoManageDetail',
    meta: {
      title: '供应商资料建档',
      auth: true
    },
    component: _import('data-center/supplier-info/supplier-info-manage/detail/details.vue')
  },
  {
    path: '/supplier/initial-qualification',
    name: 'SupplierInitialQualification',
    meta: {
      title: '供应商首营管理',
      auth: true,
      cache: true
    },
    component: _import('data-center/supplier-info/supplier-initial-qualification/index.vue')
  },
  {
    path: 'supplier-info-config',
    name: 'SupplierInfoConfig',
    meta: {
      title: '供应商资料配置',
      auth: true,
      cache: true
    },
    component: _import('data-center/supplier-info/supplier-info-config')
  }
]

export default profile
