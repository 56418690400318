import { COMMODITY_TAX_RATE_REVIEW_PATH } from '@/views/data-center/product-info/commodity-tax-rate-review/js/config'

const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  {
    path: 'first-product-info',
    name: 'FirstProductInfo',
    meta: {
      title: '首推商品资料',
      auth: true,
      cache: true
    },
    component: _import('data-center/product-info/first-product-info/first-product-info.vue')
  },
  {
    path: 'first-product-info-detail-new',
    name: 'FirstProductInfoDetails',
    meta: {
      title: '首推商品详情',
      auth: true
    },
    component: _import('data-center/product-info/first-product-info/detail-new/index.vue')
  },
  {
    path: 'product-info-manage',
    name: 'ProductInfoManage',
    meta: {
      title: '商品资料管理',
      auth: true,
      cache: true,
      fuzzy: true
    },
    component: _import('data-center/product-info/product-info-manage/product-info-manage.vue')
  },
  {
    path: 'product-provider-audit',
    name: 'ProductProviderAudit',
    meta: {
      title: '商品首营审核',
      auth: true,
      cache: true
    },
    component: _import('data-center/product-info/product-provider-audit')
  },
  {
    path: '/product/initial-qualification-manage',
    name: 'ProductInitialQualificationManage',
    meta: {
      title: '商品首营管理',
      auth: true,
      cache: true,
      keepAlive: true
    },
    component: _import('data-center/product-info/product-initial-qualification/index.vue')
  },
  {
    path: 'product-info-detail-new',
    name: 'ProductInfoDetail',
    meta: {
      title: '商品详情',
      auth: true
    },
    component: _import('data-center/product-info/product-info-manage/detail-new/index.vue')
  },
  {
    path: 'open-product-info-detail',
    name: 'openProductInfoDetail',
    meta: {
      title: '商品详情',
      auth: true
    },
    component: _import('data-center/product-info/product-info-manage/detail-new/index.vue')
  },
  {
    path: COMMODITY_TAX_RATE_REVIEW_PATH.MAIN,
    name: 'CommodityTaxRateReview',
    meta: {
      title: '商品税率审核',
      auth: true,
      cache: true
    },
    component: _import(
      'data-center/product-info/commodity-tax-rate-review/commodity-tax-rate-review.vue'
    )
  },
  {
    path: COMMODITY_TAX_RATE_REVIEW_PATH.GROUP_DETAIL,
    name: 'CommodityTaxRateReviewGroupDetail',
    meta: {
      title: '商品税率审核详情',
      auth: true
    },
    component: _import('data-center/product-info/commodity-tax-rate-review/review-detail-page.vue')
  },
  {
    path: COMMODITY_TAX_RATE_REVIEW_PATH.COMPANY_DETAIL,
    name: 'CommodityTaxRateReviewCompanyDetail',
    meta: {
      title: '商品税率审核详情',
      auth: true
    },
    component: _import('data-center/product-info/commodity-tax-rate-review/review-detail-page.vue')
  },
  {
    path: 'stock-product-set',
    name: 'stockProductSet',
    meta: {
      title: '存量商品查询',
      auth: true
    },
    component: _import('data-center/product-info/stock-product-set/stock-product-set.vue')
  },
  {
    path: 'product-code-manager',
    name: 'productCodeManager',
    meta: {
      title: '商品编码维护',
      auth: true
    },
    component: _import('data-center/product-info/product-code-manager/product-code-manager.vue')
  },
  {
    path: 'product-code-detail',
    name: 'productCodeDetail',
    meta: {
      title: '商品编码详情',
      auth: true
    },
    component: _import(
      'data-center/product-info/product-code-manager/page/product-code-detail/product-code-detail.vue'
    )
  },
  {
    path: 'view-edit-record',
    name: 'viewEditRecord',
    meta: {
      title: '查看编辑记录',
      auth: true
    },
    component: _import(
      'data-center/product-info/product-code-manager/page/view-edit-record/view-edit-record.vue'
    )
  },
  {
    path: 'product-code-replace',
    name: 'productCodeReplace',
    meta: {
      title: '商品编码替换',
      auth: true
    },
    component: _import(
      'data-center/product-info/product-code-manager/page/product-code-replace/product-code-replace.vue'
    )
  },
  {
    path: 'product/packageinfos',
    name: 'ProductPackageInfos',
    meta: {
      title: '包装信息维护',
      auth: true
    },
    component: _import('data-center/product-info/product-package-infos/index.vue')
  },
  {
    path: 'product/qualification/detail',
    name: 'ProductQualificationDetail',
    meta: {
      title: '商品资质上传',
      auth: true,
      cache: true,
      fuzzy: true
    },
    component: _import('data-center/product-info/product-qualification-detail/index.vue')
  }
]

export default profile
