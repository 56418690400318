<template>
  <div>

    <div v-if="isshowwarn" class="warning-right-bottom-wrap">
      <div class="el-notification__group">
        <h2 class="el-notification__title">每日提醒</h2>
        <div class="el-notification__content">
          <div v-for="(item,index) in warnList" :key="index">
            <p v-if="item.type === 1">您收到一条新的待办事项:<span @click="jump(item)">去处理</span></p>
            <p v-if="item.type === 2">您收到一条新的消息通知:<span @click="jump(item)">查看</span></p>
            <p v-if="item.type === 3">您收到一条新的公告:<span @click="jump(item)">查看</span></p>
          </div>
        </div>
        <div class="el-notification__closeBtn el-icon-close" @click="closewarn" />
      </div>
      <div class="no-warn" @click="noMoreClick">
        <div class="btn">
          两小时内不再提醒
        </div>
      </div>
    </div>
    <noticeDetail
      :id="idForBoard"
      :visible.sync="visibleForBoard"
    />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { handleTargetUrl } from '@/utils'
import dayjs from 'dayjs'
import noticeDetail from '@/views/data-center/notice/components/notice-details'
import {
  handleToDoList
} from '@/api/dashboard'

import {
  todoListOnLive
} from '@/api/dashboard'
export default {
  components: {
    noticeDetail
  },
  data() {
    return {
      isshowwarn: false,
      warnList: [],
      nowTime: '', // 现在的时间 时间戳
      nomoreTime: '', // 不再提醒的时间 时间戳
      idForBoard: 0,
      visibleForBoard: false
    }
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.todoListOnLiveFun()
    this.nowTime = Cookies.get('nowTime') || 0
    this.nomoreTime = Cookies.get('nomoreTime') || dayjs().valueOf()
    this.todoListOnLiveFun(this.nowTime)
    Cookies.set('nowTime', dayjs().valueOf(), {expires: 5})
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    setInterval(() => {
      this.nowTime = dayjs().valueOf()
      Cookies.set('nowTime', this.nowTime, {expires: 5})
      if (dayjs(this.nowTime).isAfter(dayjs(this.nomoreTime))) {
        this.todoListOnLiveFun(this.nowTime)
      }
    }, 5 * 60 * 1000)
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
  methods: {
    // 跳转
    async jump(pramas) {
      if (pramas.id) {
        await this.handleToDoListFun(pramas.id)
      }
      this.isshowwarn = false
      if (pramas.targetUrl.pageModule === 'IntroducerWorkManageNotice') {
        this.idForBoard = Number(pramas.targetUrl.id)
        this.visibleForBoard = true
      } else {
        handleTargetUrl(pramas.targetUrl)
      }
    },
    noMoreClick() {
      this.isshowwarn = false
      this.nomoreTime = dayjs().add(2, 'hour').valueOf()
      Cookies.set('nomoreTime', this.nomoreTime, {expires: 5})
    },
    closewarn() {
      this.isshowwarn = false
    },
    async todoListOnLiveFun(time) {
      try {
        const { data } = await todoListOnLive({
          createBeginTime: dayjs(Number(time)).format('YYYY-MM-DD HH:mm:ss')
        })
        this.warnList = data
        if (this.warnList && this.warnList.length !== 0) {
          this.isshowwarn = true
        }
      } catch (error) {
        this.isshowwarn = false
        console.log('error', error)
      }
    },
    async handleToDoListFun(id) {
      const res = await handleToDoList({ id })
      return res
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
  .warning-right-bottom-wrap{
    position: fixed;
    max-height: 450px;
    overflow: auto;
    z-index: 10000;
    right: 20px;
    bottom: 40px;
    width: 330px;
    padding: 14px ;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    position: fixed;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    transition: opacity .3s,transform .3s,left .3s,right .3s,top .4s,bottom .3s;
    .no-warn{
      margin-top: 6px;
      display: flex;
      justify-content: flex-end;
      margin-left: 13px;
      margin-right: 8px;
      .btn{
        background:  #ffe4bf;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 14px;
        color: #606266;
        cursor: pointer;
      }
    }
  }
</style>
<style lang='scss'>
//@import url(); 引入公共css类
  .warning-right-bottom-wrap{
    .el-notification__content{
      p{
        line-height: 30px;
        cursor: pointer;
      }
      span{
        background: #d5e1ff;
        padding: 2px 5px;
        border-radius: 20px;
      }
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/warning-right-bottom/index.vue"
}
</vue-filename-injector>
