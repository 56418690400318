<template>
  <div
    v-if="device===1"
    class="d2-layout-header-aside-group"
    :style="styleLayoutMainGroup"
    :class="{grayMode: grayActive}"
  >
    <!-- 半透明遮罩 -->
    <div class="d2-layout-header-aside-mask" />
    <!-- 主体内容 -->
    <div class="d2-layout-header-aside-content" flex="dir:top" :class="{'iframe-test':VUE_APP_TITLE_WARMING}">
      <!-- 顶栏 -->
      <div
        v-if="headerVisible"
        class="d2-theme-header head-self"
        :class="{'redBackgroung':VUE_APP_TITLE_WARMING}"
        :style="{
          // eslint-disable-next-line vue/this-in-template
          opacity: this.searchActive ? 0.5 : 1
        }"
        flex-box="0"
        flex
      >
        <div class="logo-group-title" :flex-box="1">
          <img src="../../assets/image/index/logo.png">
          乐药基础数据中心
        </div>
        <!-- 顶栏右侧 -->
        <div class="d2-header-right" flex-box="0">
          <div v-if="VUE_APP_TITLE_WARMING" class="warning-test">测试服环境！！！！！！！！！！</div>
          <!-- 如果你只想在开发环境显示这个按钮请添加 v-if="$env === 'development'" -->
          <d2-header-user />
        </div>
      </div>
      <!-- 下面 主体 -->
      <div class="d2-theme-container" flex-box="1" flex>
        <!-- 主体 侧边栏 -->
        <div
          v-if="sidebarVisible"
          ref="aside"
          flex-box="0"
          class="d2-theme-container-aside"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            // eslint-disable-next-line vue/this-in-template
            opacity: this.searchActive ? 0.5 : 1
          }"
        >
          <d2-menu-side />
        </div>
        <!-- 主体 -->
        <div class="d2-theme-container-main" flex-box="1" flex>
          <!-- 内容 -->
          <div v-if="!searchActive" class="d2-theme-container-main-layer" flex="dir:top">
            <!-- tab -->
            <div v-if="sidebarVisible" class="d2-theme-container-main-header" flex-box="0">
              <d2-tabs />
            </div>
            <warning v-if="pageType === 1 && headerVisible" />
            <!-- 页面 -->
            <div class="d2-theme-container-main-body" flex-box="1">
              <keep-alive :include="keepAlive">
                <router-view />
              </keep-alive>
            </div>
            <AppFooter />
          </div>
        </div>
      </div>
    </div>
    <rightBottom />
  </div>
  <!-- wap -->
  <div
    v-else
    class="d2-layout-header-aside-group wap"
    :style="styleLayoutMainGroup"
    :class="{grayMode: grayActive}"
  >
    <!-- 半透明遮罩 -->
    <div class="d2-layout-header-aside-mask" />
    <!-- 主体内容 -->
    <div class="d2-layout-header-aside-content wap" flex="dir:top">
      <!-- 顶栏 -->
      <div
        class="d2-theme-header"
        :style="{
          // eslint-disable-next-line vue/this-in-template
          opacity: this.searchActive ? 0.5 : 1
        }"
        flex-box="0"
        flex
      >
        <!-- 左侧的点击 -->
        <div class="logo-group-title" :flex-box="0">
          <Hamburger :is-active="hasAside" @toggleClick="handleHasAside" />
        </div>
        <!-- 左侧的点击 -->
        <!-- 顶栏右侧 -->
        <div class="d2-header-right" flex-box="1">
          <div class="bread-wap">
            {{ bread }}
          </div>
          <!-- <img class="user-img" src="../../assets/image/index/user.png"> -->
          <d2-header-user id="header" />
        </div>
        <!-- 顶栏右侧 -->
      </div>
      <div class="tabs-wrap">
        <d2-tabs />
      </div>
      <!-- 下面 主体 -->
      <div class="d2-theme-container" :class="{'noBread':device === 2}" flex-box="1" flex>
        <!-- 主体 侧边栏 -->
        <div
          v-show="hasAside"
          ref="aside"
          flex-box="0"
          class="d2-theme-container-aside"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            // eslint-disable-next-line vue/this-in-template
            opacity: this.searchActive ? 0.5 : 1
          }"
        >
          <d2-menu-side />
          <div class="menu-mask" @click="handleHasAside" />
        </div>
        <!-- 主体 -->
        <div class="d2-theme-container-main" flex-box="1" flex>
          <!-- 内容 -->
          <div v-if="!searchActive" class="d2-theme-container-main-layer" flex="dir:top">
            <!-- 页面 -->
            <div class="d2-theme-container-main-body" flex-box="1">
              <keep-alive :include="keepAlive">
                <router-view />
              </keep-alive>
            </div>
            <!-- <AppFooter /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- wap -->
</template>

<script>
import d2MenuSide from './components/menu-side'
import d2Tabs from './components/tabs'
import AppFooter from './components/AppFooter'
import d2HeaderUser from './components/header-user'
import Hamburger from '@/components/Hamburger'
import warning from './components/warning'
import rightBottom from './components/warning-right-bottom'
import { mapState, mapGetters, mapActions } from 'vuex'
// import mixinSearch from './mixins/search'
export default {
  name: 'D2LayoutHeaderAside',
  components: {
    d2MenuSide,
    d2Tabs,
    d2HeaderUser,
    AppFooter,
    warning,
    Hamburger,
    rightBottom
  },
  mixins: [
    // mixinSearch
  ],
  data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '200px',
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '65px',
      VUE_APP_TITLE_WARMING: Number(process.env.VUE_APP_TITLE_WARMING),
      searchActive: false,
      bread: ''
    }
  },
  computed: {
    pageType() {
      // 1商品/客户首页 2首推引入人首页 3首推审核人首页
      let type = 1
      if (this.info.roleId === 17 || this.info.roleId === 36) {
        type = 2
      } else if (this.info.roleId === 18 || this.info.roleId === 19) {
        type = 3
      }
      return type
    },
    ...mapState('d2admin', {
      keepAlive: state => state.page.keepAlive,
      grayActive: state => state.gray.active,
      transitionActive: state => state.transition.active,
      asideCollapse: state => state.menu.asideCollapse
    }),
    ...mapGetters('d2admin', {
      themeActiveSetting: 'theme/activeSetting'
    }),
    ...mapState('d2admin/user', [
      'info'
    ]),
    ...mapState('d2admin/page', {
      'sidebarVisible': 'sidebar',
      'headerVisible': 'header'
    }),
    ...mapGetters([
      'device',
      'hasAside'
    ]),
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup() {
      return {
        ...this.themeActiveSetting.backgroundImage ? {
          backgroundImage: `url('${this.$baseUrl}${this.themeActiveSetting.backgroundImage}')`
        } : {}
      }
    }
  },
  watch: {
    '$route': {
      handler(val) {
        this.bread = val.meta.title || ''
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('d2admin/menu', [
      'asideCollapseToggle',
      'hasAsideSet'
    ]),
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside() {
      this.asideCollapseToggle()
    },
    handleHasAside() {
      this.hasAsideSet(!this.hasAside)
    }
  }
}
</script>

<style lang="scss">
// 注册主题
@import '~@/assets/style/theme/register.scss';
.logo-group-title{
  display: flex;
  align-items: center;
  text-align: left;
  height: 60px;
  padding-left: 30px;
  color: #ffffff;
  img{
    height: 33px;
    width: 30px;
    margin-right: 30px;
  }
}
.head-self{
 background-image: url('../../assets/image/index/header-bg.png');
 background-size: 100% 100%;
 background-repeat: no-repeat;
}
.warning-test{
  color: red;
  background: #ffffff;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 20px;
}
.redBackgroung{
  background: red !important;
}
</style>
<style lang="scss">
.iframe-test{
  iframe{
    background: #d9f5e7;
  }
}
// wap
#app{
  .wap{
    min-width: 0;
  }
}
.noBread{
  .head-wrap{
    display: none !important;
  }
}
.wap{
  .logo-group-title{
    padding-left: 0;
  }
  .d2-header-right{
    justify-content: flex-end;
  }
  .toggle-aside-btn{
    display: none !important;
  }
  .btn-text{
    color: #000 !important;
  }
  .d2-theme-header{
    width: 100%;
  }
  .tabs-wrap{
    overflow: hidden;
    width: 100%;
    padding-bottom: 10px;
  }
  .el-tabs__item{
    height: 32px !important;
    line-height: 30px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .bread-wap{
    width: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;
  }
  .d2-theme-container-aside{
    position: fixed !important;
    top: 60px;
    left: 0;
    bottom: 0;
    z-index: 3000;
    background: #ffffff;
  }
  .menu-mask{
    position: fixed ;
    top: 60px;
    left: 200px;
    right: 0;
    bottom: 0;
    z-index: 2999;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/layout.vue"
}
</vue-filename-injector>
