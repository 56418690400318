export default {
  namespaced: true,
  state: {
    pasteInterval: ''
  },
  mutations: {
    // 设置  是否需要修改密码
    setpasteInterval(state, val) {
      state.pasteInterval = val
    }
  },
  actions: {
    // 设置客户端型号
    pasteIntervalSet({
      commit
    }, device) {
      commit('setpasteInterval', device)
    }
  }
}

