import ajax from '@/utils/request'

// 首页（获取用户的菜单，按钮权限）
export function getMenu(params) {
  return ajax.post({
    url: '/auth/index/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 登录接口
export function login(params) {
  return ajax.post({
    url: '/auth/login/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 登出接口
export function logoutOnline(params) {
  return ajax.post({
    url: '/auth/logout/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 登出接口
export function getCustomerCertUpLoadToken(params) {
  return ajax.post({
    url: '/auth/getCustomerCertUpLoadToken',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 修改密码
export function changePassword(params) {
  return ajax.post({
    url: '/auth/resetPassword/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 首页搜索ID无数据
export function pullCustomer(params) {
  return ajax.post({
    url: '/auth/pullCustomer',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

// 获取顶部浮框数据
export function getTopFloatData(params) {
  return ajax.post({
    url: '/auth/getTopFloatData/v100',
    params,
    type: 2 // 1 本地mock 2 测试服
  })
}

/**
 * 获取业务角色类型
 * @param {object} params
 * @returns
 */
export function getRoleType(params) {
  return ajax.post({
    url: '/role/getBusinessRoleType/v100',
    params
  })
}

/**
 * zmm - 通过签名无感登录
 * @param {object} params { ysbProviderId, expireTimestamp, sign }
 * @returns
 */
export function autoLogin(params) {
  return ajax.post({
    url: '/auth/loginFromPms/v100',
    params
  })
}

/**
 * zmm - 通过签名无感登录
 * @param {object} params { ysbProviderId, expireTimestamp, sign }
 * @returns
 */
export function loginViaLeyoKeyToken(params) {
  return ajax.post({
    url: '/Index/loginViaLeyoKeyToken/v486',
    params
  })
}
