<template>
  <div class="layer-body">
    <transition>
      <div v-show="show" ref="layerWrapper" class="layer-wrapper" :style="transformStyle">
        <div
          class="layer-wrapper-title"
          :class="{'draggable':draggable}"
          :style="titleStyle||''"
          @mousedown="mousedown"
        >
          <i class="semi-rect" />
          <div class="layer-title-text">{{ title || ' ' }}</div>
          <div v-if="showCloseIcon" class="layer-close" @click.stop="_close">
            <i class="el-icon-close" />
          </div>
        </div>
        <div v-if="!html" class="layer-content-wrapper">
          <layer-content :layer-id="layerId" :data.sync="data" @close="_close" @yes="_yes" />
        </div>
        <div v-else>
          <div v-html="html" />
        </div>
        <div v-if="showCancelBtn||showConfirmBtn" class="layer-foot" :style="btnWrapperStyle||''">
          <div v-if="showCancelBtn" class="layer-cancel" :style="cancelBtnStyle||''" @click="_cancel">{{ cancelText }}
          </div>
          <div v-if="showConfirmBtn" class="layer-confirm" :style="confirmBtnStyle||''" @click="_yes()">{{ confirmText }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      dragging: false,
      dragData: {
        translateX: 0,
        translateY: 0,
        beforeDragX: 0,
        beforeDragY: 0,
        preTranslateX: 0,
        preTranslateY: 0
      }

    }
  },
  computed: {
    transformStyle() {
      return {
        transform: `matrix(1,0,0,1,${this.dragData.translateX + this.dragData.preTranslateX},${this.dragData.translateY + this.dragData.preTranslateY})`
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.show = true
      this.dragData.originX = this.$refs.layerWrapper.offsetLeft
      this.dragData.originY = this.$refs.layerWrapper.offsetTop
    })
  },
  methods: {
    _close() {
      // 动画效果结束再关闭
      this.show = false
      if (typeof this.close === 'function') {
        this.close()
      }
      const t = setTimeout(() => {
        clearTimeout(t)
        this.$layer.close(this.layerId)
      }, 400)
    },
    _cancel() {
      if (typeof this.no === 'function') {
        this.no(this.layerId, this.data)
      } else {
        this._close()
      }
    },
    _yes(data) {
      if (typeof this.yes === 'function') {
        data = data || this.data
        this.yes(this.layerId, data)
      } else {
        this._close()
      }
    },
    mousedown(e) {
      if (!this.draggable) return
      this.dragging = true
      this.dragData.beforeDragX = e.clientX
      this.dragData.beforeDragY = e.clientY
      window.onmousemove = this.mousemove
      window.onmouseup = this.mouseup
    },
    mousemove(e) {
      if (!this.dragging) return
      this.dragData.translateX = e.clientX - this.dragData.beforeDragX
      this.dragData.translateY = e.clientY - this.dragData.beforeDragY
    },
    mouseup(e) {
      this.dragging = false
      this.dragData.preTranslateX += this.dragData.translateX
      this.dragData.preTranslateY += this.dragData.translateY
      this.dragData.translateX = 0
      this.dragData.translateY = 0
      window.onmousemove = null
      window.onmouseup = null
    }
  }
}
</script>

<style scoped lang="scss">
.semi-rect{
  position: absolute;
  left: 8px;
  top: 50%;
  display: block;
  width: 4px;
  height: 20px;
  transform: translateY(-50%);
  overflow: hidden;
  &::before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left: 10px solid $color-primary-2;
  }
}
.layer-body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .layer-wrapper {
    background: white;
    border-radius: 4px;
    position: relative;
    top: -20px;
    min-width: 300px;
    box-shadow: 2px 2px 10px 3px #444;
    max-height: 90%;
    overflow: auto;
    /*v-enter 是进入之前，元素的起始状态*/
    /*v-leave-to 离开之后动画的终止状态*/
    &.v-enter,
    &.v-leave-to {
      top: -100px !important;
      opacity: 0; /*透明度*/
    }
    /*入场(离场)动画的时间段   */
    &.v-enter-active,
    &.v-leave-active {
      transition: all 0.4s ease;
    }
    .draggable {
      cursor: all-scroll;
    }
    .layer-wrapper-title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 62px;
      padding: 20px 23px 20px 20px;
      border-radius: 4px 4px 0 0;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.0614347);
      // min-height: 30px;
      box-sizing: border-box;
      .layer-title-text{
        color: #333;
        font-size: 16px;
        line-height: 22px;
      }
      .layer-close {
        position: relative;
        cursor: pointer;
        font-weight: bolder;
      }
    }
    .layer-content-wrapper {
      overflow: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        background: #3673FF;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 0;
        background: #ECF2FF;
      }
    }
    .layer-foot {
      display: flex;
      justify-content: flex-end;
      padding: 6px;
      border-top: 1px solid #e8e8e8;
      text-align: center;
      color: white;
      font-size: 12px;
      .layer-cancel {
        background: #f56c6c;
        cursor: pointer;
        border-radius: 4px;
        padding: 7px 15px;
      }
      .layer-confirm {
        cursor: pointer;
        padding: 7px 15px;
        background: #456be5;
        margin-left: 15px;
        border-radius: 4px;
      }
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/plugin/layer/layer.vue"
}
</vue-filename-injector>
