const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'declare-contract-management',
    name: 'DeclareContractManagement',
    meta: {
      title: '首推合同管理',
      auth: true,
      cache: true
    },
    component: _import('data-center/first-declare-contract/contract-management/contract-management.vue')
  }
]

export default profile
