import store from '@/store'

// const { body } = document
// const WIDTH = 992 // refer to Bootstrap's responsive design

export default {
  watch: {
    $route(route) {
      if (this.$store.getters.device === 2 && this.$store.getters.hasAside) {
        store.dispatch('d2admin/menu/hasAsideSet', false)
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      store.dispatch('datacenter/publicGlobalPropertise/deviceSet', 2)
      store.dispatch('d2admin/menu/hasAsideSet', false)
      store.dispatch('d2admin/menu/asideCollapseSet', false)
    } else {
      store.dispatch('datacenter/publicGlobalPropertise/deviceSet', 1)
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        if (isMobile) {
          store.dispatch('datacenter/publicGlobalPropertise/deviceSet', 2)
          store.dispatch('d2admin/menu/hasAsideSet', false)
          store.dispatch('d2admin/menu/asideCollapseSet', false)
        } else {
          store.dispatch('datacenter/publicGlobalPropertise/deviceSet', 1)
        }
      }
    }
  }
}
