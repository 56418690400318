const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const profile = [
  // 演示页面
  {
    path: 'introducer-work-manage-product',
    name: 'IntroducerWorkManageProduct',
    meta: {
      title: '首推商品进程追踪',
      auth: true,
      cache: true
    },
    component: _import('data-center/first-product-details/first-product-details.vue')
  }
]

export default profile
