// Tab 配置
export const TAB_CONFIG = {
  waiting: {
    value: 'waiting',
    label: '待审核',
    authKey: 'product_special_tax_wait_audit'
  },
  pass: {
    value: 'pass',
    label: '已审核',
    authKey: 'product_special_tax_audited'
  },
  corporateTaxRateWaiting: {
    value: 'corporateTaxRateWaiting',
    label: '公司税率待审核',
    authKey: 'product_special_tax_provider_wait_audit'
  },
  corporateTaxRatePass: {
    value: 'corporateTaxRatePass',
    label: '公司税率已审核',
    authKey: 'product_special_tax_provider_audited'
  }
}

// 列表筛选区默认参数
const DEFAULT_SEARCH_PARAMS = {
  companyId: '',
  ysbProviderId: '',
  productName: '',
  approval: '',
  factory: '',
  productCode: '',
  startTime: '',
  endTime: '',
  // 分页参数
  page: 1,
  pageSize: 10
}

// 获取列表筛选区默认参数
export const getTabsDefaultSearchParams = () => {
  return {
    [TAB_CONFIG.waiting.value]: { ...DEFAULT_SEARCH_PARAMS, submitUserName: '' },
    [TAB_CONFIG.pass.value]: { ...DEFAULT_SEARCH_PARAMS, auditUserName: '' },
    [TAB_CONFIG.corporateTaxRateWaiting.value]: { ...DEFAULT_SEARCH_PARAMS, submitUserName: '' },
    [TAB_CONFIG.corporateTaxRatePass.value]: { ...DEFAULT_SEARCH_PARAMS, auditUserName: '' }
  }
}

// 帝豪公司 id
export const DEFAULT_COMPANY_ID = 3

// 帝豪公司 ysbProviderId
export const DEFAULT_YSB_PROVIDER_ID = 781

// 集团、公司层面-标识
export const GROUP_COMPANY_MODE = {
  GROUP: 'group',
  COMPANY: 'company'
}

// 商品税率审核页面 path
export const COMMODITY_TAX_RATE_REVIEW_PATH = {
  MAIN: 'commodity-tax-rate-review',
  GROUP_DETAIL: 'commodity-tax-rate-review-group-detail',
  COMPANY_DETAIL: 'commodity-tax-rate-review-company-detail'
}

// 审批操作
export const AUDIT_OPERATION = {
  1: '提交审批',
  2: '审核通过',
  3: '审核驳回'
}

// 审批状态
export const AUDIT_STATUS = {
  1: '待审核',
  2: '审核通过',
  3: '审核驳回'
}
