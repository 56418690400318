<template>
  <div class="warning-component">
    <div v-if="draftProductCount || refuseCustomerCount || draftCustomerCount" class="warning">
      <div v-if="draftProductCount" style="marginRight: 20px">
        <i class="el-icon-warning" />
        有{{ draftProductCount }}个草稿状态商品请及时处理!
        <span class="btn" @click="handleDraftProduct">
          点击查看
        </span>
      </div>
      <div v-if="refuseCustomerCount" style="marginRight: 20px">
        <i class="el-icon-warning" />
        有{{ refuseCustomerCount }}个驳回客户建档请及时处理！
        <span class="btn" @click="handleRefuseCustomer">马上确认</span>
      </div>
      <div v-if="draftCustomerCount" style="marginRight: 20px">
        <i class="el-icon-warning" />
        有{{ draftCustomerCount }}个存入草稿列表的客户建档，请补充无法建档的原因！
        <span class="btn" @click="handleDraftCustomerCount">马上确认</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getTopFloatData } from '@/api/auth'
import { mapState } from 'vuex'
import { EventBus } from '@/utils/eventBus'

export default {
  components: {},
  data() {
    return {
      draftProductCount: 0, // 草稿状态的商品数量
      refuseCustomerCount: 0, // 客户建档审批驳回数量
      draftCustomerCount: 0, // 存量保存草稿未添加备注数量,
      basic: 0 // 0没有商品 1有商品
    }
  },
  computed: {
    ...mapState('d2admin/user', [
      'info'
    ])
  },
  watch: {
    $route() {
      this.getTopFloatDataFun()
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTopFloatDataFun()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  methods: {
    // 草稿状态的商品数量
    handleDraftProduct() {
      this.$router.push({
        name: 'ProductInfoManage',
        query: {
          status: 1,
          creator: this.info.name
        }
      })
      if (this.$route.name === 'ProductInfoManage') {
        EventBus.$emit('getFromTopFloatProductInfoManage')
      }
    },
    // 驳回客户
    handleRefuseCustomer() {
      this.$router.push({
        name: 'CuntomerInfoSet',
        query: {
          tabCode: 'reject_file',
          applyUser: this.info.name
        }
      })
      if (this.$route.name === 'CuntomerInfoSet') {
        EventBus.$emit('getFromTopFloat')
      }
    },
    // 存量保存草稿未添加备注数量
    handleDraftCustomerCount() {
      this.$router.push({
        name: 'CuntomerInfoSet',
        query: {
          tabCode: 'draft_file',
          applyUser: this.info.name
        }
      })
      if (this.$route.name === 'CuntomerInfoSet') {
        EventBus.$emit('getFromTopFloat')
      }
    },
    async getTopFloatDataFun() {
      try {
        const { data } = await getTopFloatData()
        this.draftProductCount = data.draftProductCount
        this.refuseCustomerCount = data.refuseCustomerCount
        this.draftCustomerCount = data.draftCustomerCount
        this.basic = data.basic
      } catch (error) {
        console.log('error', error)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.warning-component{
  background-color: #f6f6f6;
}
.warning{
    padding: 8px 10px;
    border-radius: 4px;
    background-color: #fef0f0;
    color: #f56c6c;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin: 10px 10px 0 10px;
    .el-icon-warning{
      font-size: 15px;
      margin-right: 10px;
    }
    .btn{
      color: #409EFF;
      cursor: pointer;
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/warning/index.vue"
}
</vue-filename-injector>
